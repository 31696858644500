import { library } from '@fortawesome/fontawesome-svg-core'
import { faBars as fasBars } from '@fortawesome/free-solid-svg-icons/faBars'
import { faInfinity as fasInfinity } from '@fortawesome/free-solid-svg-icons/faInfinity'
import { faMinus as fasMinus } from '@fortawesome/free-solid-svg-icons/faMinus'
import { faPlus as fasPlus } from '@fortawesome/free-solid-svg-icons/faPlus'
import { faFire as fasFire } from '@fortawesome/free-solid-svg-icons/faFire'
import { faExchangeAlt as fasExchangeAlt } from '@fortawesome/free-solid-svg-icons/faExchangeAlt'
import { faChevronLeft as fasChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { InitModule } from '@/types/app'

export const initFontawesome: InitModule = (app) => {
    library.add({
        fasBars,
        fasInfinity,
        fasMinus,
        fasPlus,
        fasFire,
        fasExchangeAlt,
        fasChevronLeft,
    })

    app.component('FontAwesomeIcon', FontAwesomeIcon)
}
