<template>
    <button
        v-if="!isAuthenticated"
        @click="coreStore.showLoginModal = true"
        class="bg-black text-white px-4 py-2 rounded-full focus:outline-none"
    >
        Connect
    </button>

    <BaseDropdown v-else right>
        <template #button>
            <MenuButton class="flex items-center focus:outline-none">
                <div class="hidden md:block">
                    <div class="text-gems-blue font-semibold">{{ username }}</div>
                    <div v-if="userBalance" class="text-sm">
                        {{ `${userBalance.balance} ${userBalance.symbol}` }}
                    </div>
                </div>
                <UserCircleIcon class="md:hidden w-7 h-7 ml-2" aria-hidden="true" />
                <ChevronDownIcon class="w-5 h-5 ml-1 mt-1" aria-hidden="true" />
            </MenuButton>
        </template>

        <div class="w-full space-y-1">
            <MenuItem v-slot="{ active }">
                <router-link :to="{ name: 'Profile', params: { account: username } }">
                    <button
                        class="rounded-md w-full px-2 py-2 text-sm"
                        :class="[
                            active ? 'bg-gems-blue text-white' : 'text-gray-900 dark:text-white',
                        ]"
                    >
                        My Inventory
                    </button>
                </router-link>
            </MenuItem>
            <MenuItem v-if="isAdmin" v-slot="{ active }">
                <router-link :to="{ name: 'Stats' }">
                    <button
                        class="rounded-md w-full px-2 py-2 text-sm"
                        :class="[
                            active ? 'bg-gems-blue text-white' : 'text-gray-900 dark:text-white',
                        ]"
                    >
                        Resizer Stats
                    </button>
                </router-link>
            </MenuItem>
            <hr class="border-t border-gray-200 dark:border-gray-700 w-full" />
            <MenuItem v-slot="{ active }">
                <button
                    @click="userLogout"
                    :class="[
                        active ? 'text-white' : 'text-red-600 bg-opacity-20',
                        'rounded-md w-full px-2 py-2 text-sm',
                    ]"
                    class="bg-red-600"
                >
                    Logout
                </button>
            </MenuItem>
        </div>
    </BaseDropdown>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import { useCoreStore } from '@/stores/core.store'
import { isAuthenticated, logout, userBalance, username } from 'vue-3-useeosiowallet'
import { ChevronDownIcon } from '@heroicons/vue/solid'
import { UserCircleIcon } from '@heroicons/vue/outline'
import BaseDropdown from '@/components/base/BaseDropdown.vue'
import { MenuButton, MenuItem } from '@headlessui/vue'

export default defineComponent({
    name: 'UserMenu',
    components: { BaseDropdown, MenuButton, MenuItem, ChevronDownIcon, UserCircleIcon },
    setup() {
        const coreStore = useCoreStore()

        const isAdmin = computed(() => {
            const admins = ['jan.gem']
            return admins.find((x) => x === username.value)
        })

        const userLogout = async () => {
            try {
                await logout()
            } catch (e) {
                console.error(e)
            }
        }

        return { coreStore, isAuthenticated, username, userBalance, userLogout, isAdmin }
    },
})
</script>
