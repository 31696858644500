<template>
    <div>
        <button class="flex items-center focus:outline-none" @click="isOpen = true">
            <img
                :src="require(`@/assets/images/${selectedChain.id}-logo.webp`)"
                alt="Blockchain Logo"
                width="32"
            />
        </button>

        <BaseModal v-model="isOpen" title="Switch Network">
            <div class="flex justify-around mt-10">
                <button
                    v-for="chain in availableChains"
                    :key="chain"
                    class="focus:outline-none"
                    type="button"
                    @click="selectChain(chain.id)"
                >
                    <img
                        :src="require(`@/assets/images/${chain.id}-logo.webp`)"
                        alt="Blockchain Logo"
                        width="50"
                    />
                    <span class="font-semibold text-lg mt-1 uppercase">{{ chain.id }}</span>
                </button>
            </div>
        </BaseModal>
    </div>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue'
import BaseModal from '@/components/base/BaseModal.vue'
import { availableChains, changeChain, selectedChain } from 'vue-3-useeosiowallet'
import { useRouter } from 'vue-router'
import { useCoreStore } from '@/stores/core.store'

export default defineComponent({
    name: 'SelectChain',
    components: { BaseModal },
    setup() {
        const router = useRouter()
        const coreStore = useCoreStore()

        const isOpen = ref<boolean>(false)

        const selectChain = async (id: string) => {
            isOpen.value = false
            await changeChain(id)
            await router.push({ name: 'PageHome' })
            localStorage.setItem('chain', JSON.stringify(id))
            await coreStore.initDotgems()
        }

        return { isOpen, availableChains, selectChain, selectedChain }
    },
})
</script>
