<template>
    <router-link :to="{ name: 'PageAssetDetails', params: { asset_id: asset.asset_id } }">
        <BaseCard :title="asset.name" :subtitle="`#${asset.asset_id}`">
            <template #image>
                <ImageBundle v-if="!asset.data.video" :images="[asset.data.img]" />
                <div v-else class="relative aspect-w-1 aspect-h-1">
                    <video autoplay loop class="rounded-lg object-contain" muted>
                        <source
                            :src="`https://ipfs.io/ipfs/${asset.data.video}`"
                            type="video/mp4"
                        />
                        Your browser does not support the video tag.
                    </video>
                </div>
            </template>

            <div class="flex justify-between items-center">
                <div>
                    <div class="text-sm text-gray-400">Mint</div>
                    <span class="text-xl font-semibold">#{{ asset.template_mint }}</span>
                    /
                    {{ asset.template ? asset.template.issued_supply : 0 }}
                </div>
                <div v-if="rarity" class="text-right">
                    <div class="text-sm text-gray-400">Rarity</div>
                    <AssetRarity :rarity="rarity" />
                </div>
            </div>
        </BaseCard>
    </router-link>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'
import BaseCard from '@/components/base/BaseCard.vue'
import ImageBundle from '@/components/common/ImageBundle.vue'
import { IAsset } from '@/types/atomicapi/asset'
import AssetRarity from '@/components/common/AssetRarity.vue'

export default defineComponent({
    name: 'CardAsset',
    components: { AssetRarity, BaseCard, ImageBundle },
    props: {
        asset: { type: Object as PropType<IAsset>, required: true },
    },
    setup(props) {
        const rarity = computed(() =>
            props.asset.template ? props.asset.template.immutable_data.rarity : '',
        )

        return { rarity }
    },
})
</script>
