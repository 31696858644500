
import { computed, defineComponent, onBeforeMount, ref, watch } from 'vue'
import { IAsset } from '@/types/atomicapi/asset'
import { useUserStore } from '@/stores/user.store'
import { IDataObject } from '@/types/app'
import { useRoute, useRouter } from 'vue-router'
import { useCoreStore } from '@/stores/core.store'
import EndOfPageTrigger from '@/components/common/EndOfPageTrigger.vue'
import { useCollectionsStore } from '@/stores/collections.store'
import { selectedChain, transact, username } from 'vue-3-useeosiowallet'
import CardAsset from '@/components/common/CardAsset.vue'
import SelectSort from '@/components/common/SelectSort.vue'
import SelectRarity from '@/components/common/SelectRarity.vue'
import { useHead } from '@vueuse/head'
import GridHeader from '@/components/common/GridHeader.vue'

export default defineComponent({
    name: 'PageProfile',
    components: { GridHeader, SelectRarity, SelectSort, CardAsset, EndOfPageTrigger },
    props: {
        account: { type: String, required: true },
    },
    setup(props) {
        useHead({
            title: computed(
                () =>
                    `dotGems | ${selectedChain.value.id.toUpperCase()} | Inventory: ${
                        props.account
                    }`,
            ),
        })
        const router = useRouter()
        const route = useRoute()
        const routeQuery = computed(() => route.query)
        const coreStore = useCoreStore()
        const userStore = useUserStore()
        const isLoading = ref(false)
        const assets = ref<IAsset[]>([])
        const page = ref(1)

        const sortOptions = [
            { id: 1, value: 'transferred', label: 'Transferred', disabled: false },
            { id: 2, value: 'asset_id', label: 'Asset ID', disabled: false },
            { id: 3, value: 'updated', label: 'Updated', disabled: false },
            { id: 4, value: 'minted', label: 'Minted', disabled: false },
            { id: 5, value: 'template_mint', label: 'Mint #', disabled: false },
        ]

        const fetchParams = (): IDataObject => {
            return {
                owner: props.account,
                limit: coreStore.assetsPerPage.toString(),
                page: page.value.toString(),
                collection_whitelist: useCollectionsStore().collectionIds.join(','),
                order: coreStore.defaultOrder,
                sort: 'asset_id',
                'data.rarity': '',
                ...routeQuery.value,
            }
        }

        const loadData = async () => {
            isLoading.value = true
            page.value = 1
            const params = fetchParams()
            assets.value = await userStore.fetchAssets(params)
            isLoading.value = false
        }

        const loadNextPage = async () => {
            if (userStore.pagesMax) return
            page.value++

            const params = fetchParams()
            const data = await userStore.fetchMoreAssets(params)
            assets.value = assets.value.concat(data)
        }

        const unpack = async (asset_id: string) => {
            const action = {
                account: 'atomicassets',
                name: 'transfer',
                data: {
                    from: username.value,
                    to: 'unpack.gems',
                    asset_ids: [asset_id],
                    memo: 'unbox',
                },
            }
            try {
                await transact([action])
                await router.push({ name: 'Unpack', params: { account: props.account, asset_id } })
            } catch (e) {
                console.error('Unpack transaction failed!', e)
            }
        }

        onBeforeMount(async () => {
            await loadData()
            page.value = Math.ceil(assets.value.length / 12) ?? 1
        })

        watch(routeQuery, async () => {
            await loadData()
        })

        const showUnpackButton = (asset: IAsset) => {
            return (
                asset.schema.schema_name.includes('pack') &&
                props.account === username.value &&
                (asset.collection.collection_name.includes('.gems') ||
                    asset.collection.collection_name.includes('graffbattles'))
            )
        }

        return {
            assets,
            isLoading,
            loadNextPage,
            unpack,
            sortOptions,
            username,
            routeQuery,
            showUnpackButton,
        }
    },
})
