
import { computed, defineComponent } from 'vue'
import { useCoreStore } from '@/stores/core.store'
import { isAuthenticated, logout, userBalance, username } from 'vue-3-useeosiowallet'
import { ChevronDownIcon } from '@heroicons/vue/solid'
import { UserCircleIcon } from '@heroicons/vue/outline'
import BaseDropdown from '@/components/base/BaseDropdown.vue'
import { MenuButton, MenuItem } from '@headlessui/vue'

export default defineComponent({
    name: 'UserMenu',
    components: { BaseDropdown, MenuButton, MenuItem, ChevronDownIcon, UserCircleIcon },
    setup() {
        const coreStore = useCoreStore()

        const isAdmin = computed(() => {
            const admins = ['jan.gem']
            return admins.find((x) => x === username.value)
        })

        const userLogout = async () => {
            try {
                await logout()
            } catch (e) {
                console.error(e)
            }
        }

        return { coreStore, isAuthenticated, username, userBalance, userLogout, isAdmin }
    },
})
