
import { computed, defineComponent, onMounted, ref } from 'vue'
import { IArticle, useEditorialStore } from '@/stores/editorial.store'
import { useHead } from '@vueuse/head'
import { selectedChain } from 'vue-3-useeosiowallet'

export default defineComponent({
    name: 'PageArticle',
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        useHead({
            title: computed(
                () => `dotGems | ${selectedChain.value.id.toUpperCase()} | Article #${props.id}`,
            ),
        })
        const editorialStore = useEditorialStore()
        const article = ref<IArticle | null>(null)
        onMounted(async () => {
            article.value = await editorialStore.getArticleById(Number(props.id))
        })

        return { article }
    },
})
