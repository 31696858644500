
import { defineComponent, PropType } from 'vue'
import { IAsset } from '@/types/atomicapi/asset'

export default defineComponent({
    name: 'AssetHeader',
    props: {
        asset: { type: Object as PropType<IAsset>, required: true },
    },
})
