import { RouteRecordRaw } from 'vue-router'
import PageProfile from '@/pages/user/PageProfile.vue'
import PageUnpack from '@/pages/user/PageUnpack.vue'
import LayoutSidemenu from '@/layouts/LayoutSidemenu.vue'
import MenuUser from '@/components/user/MenuUser.vue'
import LayoutHome from '@/layouts/LayoutHome.vue'

export const userRoutes: Array<RouteRecordRaw> = [
    {
        path: '/user',
        // Set Layout Component
        component: LayoutSidemenu,
        // Set Pages using Layout
        children: [
            {
                path: ':account',
                name: 'Profile',
                components: {
                    default: PageProfile,
                    menu: MenuUser,
                },
                props: true,
            },
            {
                path: ':account/unpack/:asset_id',
                name: 'Unpack',
                component: PageUnpack,
                props: true,
            },
        ],
    },
    {
        path: '/unpack',
        // Set Layout Component
        component: LayoutHome,
        // Set Pages using Layout
        children: [
            {
                path: ':account/:asset_id',
                name: 'Unpack',
                component: PageUnpack,
                props: true,
            },
        ],
    },
]
