
import { defineComponent } from 'vue'
import BaseModal from '@/components/base/BaseModal.vue'
import { useCoreStore } from '@/stores/core.store'
import BaseSlideout from '@/components/base/BaseSlideout.vue'

export default defineComponent({
    name: 'LayoutSidemenu',
    components: { BaseSlideout },
    setup() {
        const coreStore = useCoreStore()

        return { coreStore }
    },
})
