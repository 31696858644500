
import { defineComponent, PropType } from 'vue'

interface TabItem {
    id: number
    name: string
}

export default defineComponent({
    name: 'BaseTabs',
    props: {
        modelValue: { type: Number },
        tabs: { type: Array as PropType<TabItem[]> },
    },
    emits: ['update:modelValue'],
})
