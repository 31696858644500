
import { computed, defineComponent, PropType, ref } from 'vue'
import { ISale } from '@/types/atomicapi/sale'
import ImageBundle from '@/components/common/ImageBundle.vue'
import BaseCard from '@/components/base/BaseCard.vue'
import { convertPrice } from '@/helpers/pureFunctions'
import { PriceSymbol } from '@/types/app'

export default defineComponent({
    name: 'CardForSale',
    components: { BaseCard, ImageBundle },
    props: {
        sale: { type: Object as PropType<ISale>, required: true },
    },
    setup(props) {
        const imgIndex = ref(0)
        const assetsCount = computed(() => props.sale.assets.length)

        const title = computed(() => {
            if (assetsCount.value > 1) return `${assetsCount.value}  NFTs Bundle`
            return props.sale.assets[0].name
        })

        const images = computed(() => props.sale.assets.map((a) => a.data.img))

        const tokenPrice = computed(() => {
            const { amount, token_symbol } = props.sale.price
            return convertPrice({
                amount,
                symbol: token_symbol as PriceSymbol,
                omitSymbol: true,
                toFixed: 2,
                prettify: true,
            })
        })

        return { tokenPrice, title, images, imgIndex }
    },
})
