<template>
    <nav aria-label="Tabs" class="flex">
        <button
            v-for="tab in tabs"
            :key="tab.name"
            :class="[
                tab.id === modelValue
                    ? 'border-gems-blue text-gems-blue'
                    : 'text-gray-500 hover:text-gems-blue border-white dark:border-black hover:border-gems-blue',
            ]"
            class="w-full py-2 px-1 text-lg text-center border-b-2 font-medium focus:outline-none"
            @click="$emit('update:modelValue', tab.id)"
        >
            {{ tab.name }}
        </button>
    </nav>
    <div class="py-10 border-b-2 border-white dark:border-black"><slot /></div>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue'

interface TabItem {
    id: number
    name: string
}

export default defineComponent({
    name: 'BaseTabs',
    props: {
        modelValue: { type: Number },
        tabs: { type: Array as PropType<TabItem[]> },
    },
    emits: ['update:modelValue'],
})
</script>
