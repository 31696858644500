
import { computed, defineComponent, onMounted, PropType } from 'vue'
import { SpeakerphoneIcon, XIcon } from '@heroicons/vue/outline'
import { IAlert, useAlertStore } from '@/stores/alert.store'
import { selectedChain } from 'vue-3-useeosiowallet'

export default defineComponent({
    name: 'BaseAlert',
    props: {
        alert: { type: Object as PropType<IAlert>, required: true },
    },
    components: {
        SpeakerphoneIcon,
        XIcon,
    },
    setup(props) {
        const alertStore = useAlertStore()
        const variantStyles = computed(() => {
            switch (props.alert.variant) {
                case 'success':
                    return {
                        50: 'hover:bg-green-50',
                        500: 'hover:bg-green-500',
                        600: 'bg-green-600',
                        800: 'bg-green-800',
                    }
                case 'error':
                    return {
                        50: 'hover:bg-red-50',
                        500: 'hover:bg-red-500',
                        600: 'bg-red-600',
                        800: 'bg-red-800',
                    }
                default:
                    return {
                        50: 'hover:bg-blue-50',
                        500: 'hover:bg-blue-500',
                        600: 'bg-blue-600',
                        800: 'bg-blue-800',
                    }
            }
        })

        const txHashButton = computed(() => {
            if (!props.alert.txHash) return null

            const bloksUrl: string[] = ['https://']
            if (selectedChain.value.id !== 'eos') bloksUrl.push(`${selectedChain.value.id}.`)
            bloksUrl.push(`bloks.io/transaction/`)
            bloksUrl.push(props.alert.txHash)
            return bloksUrl.join('')
        })
        const remove = () => alertStore.removeAlert(props.alert.id)

        onMounted(async () => setTimeout(() => remove(), props.alert.showSeconds * 1000))
        return { variantStyles, remove, txHashButton }
    },
})
