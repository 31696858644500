
import { computed, defineComponent, ref } from 'vue'
import UnpackAnimation from '@/components/unpack/UnpackAnimation.vue'
import { rpc, selectedChain } from 'vue-3-useeosiowallet'
import { IAsset } from '@/types/atomicapi/asset'
import { atomicApi } from '@/helpers/atomicApi'
import CardAsset from '@/components/common/CardAsset.vue'
import { useHead } from '@vueuse/head'

export default defineComponent({
    name: 'PageUnpack',
    components: { CardAsset, UnpackAnimation },
    props: {
        asset_id: { type: String, required: true },
    },
    setup(props) {
        useHead({
            title: computed(
                () =>
                    `dotGems | ${selectedChain.value.id.toUpperCase()} | Unpack Asset #${
                        props.asset_id
                    }`,
            ),
        })
        const assets = ref<IAsset[]>([])

        const fetchAssetIds = async () => {
            const result = await rpc.history_get_actions('unpack.gems', -1, -100)
            const found = result.actions
                .map((x: any) => x.action_trace.act)
                .filter((x: any) => x.name === 'unpacklog')
                .find((x: any) => x.data.pack_asset_id === props.asset_id)

            if (!found)
                throw new Error(`Unable to find unpacklog action with asset id: ${props.asset_id}`)

            return found.data.unpack_asset_ids
        }

        // TODO add loading markup
        const isLoading = ref(false)
        const fetchAssets = async () => {
            isLoading.value = true
            try {
                const assetIds = await fetchAssetIds()
                assets.value = (await atomicApi('assets', 'assets', {
                    ids: assetIds.join(','),
                })) as IAsset[]
            } catch (e) {
                console.error('Failed to load unpacked assets!', e)
            } finally {
                isLoading.value = false
            }
        }

        return { assets, fetchAssets, isLoading }
    },
})
