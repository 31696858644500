<template>
    <div class="text-center">
        <h1 class="mb-1">{{ asset.name }}</h1>
        <div class="mb-10 text-lg text-gray-500">
            owned by
            <router-link :to="{ name: 'Profile', params: { account: asset.owner } }" class="link">
                {{ asset.owner }}
            </router-link>
        </div>
    </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { IAsset } from '@/types/atomicapi/asset'

export default defineComponent({
    name: 'AssetHeader',
    props: {
        asset: { type: Object as PropType<IAsset>, required: true },
    },
})
</script>
