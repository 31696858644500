import { RouteRecordRaw } from 'vue-router'
import MenuCollections from '@/components/collections/MenuCollections.vue'
import PageTemplates from '@/pages/collections/PageTemplates.vue'
import LayoutSidemenu from '@/layouts/LayoutSidemenu.vue'

export const collectionsRoutes: Array<RouteRecordRaw> = [
    {
        path: '/collections',
        // Set Layout Component
        component: LayoutSidemenu,
        // Set Pages using Layout
        children: [
            {
                path: '',
                name: 'Collections',
                components: {
                    default: PageTemplates,
                    menu: MenuCollections,
                },
            },
        ],
    },
]
