
import { computed, defineComponent, PropType, ref } from 'vue'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/solid'
import { getResizerUrl } from '@/helpers/resizer'

export default defineComponent({
    name: 'ImageBundle',
    components: { ChevronLeftIcon, ChevronRightIcon },
    props: {
        index: { type: Number },
        images: { type: Array as PropType<string[]>, required: true },
    },
    emits: ['update:index'],
    setup(props, { emit }) {
        const imgPage = ref(1)

        const imgCount = computed(() => props.images.length)
        const isBundle = computed(() => imgCount.value > 1)
        const selectedImg = computed(() => props.images[imgPage.value - 1])

        const nextImage = () => {
            if (imgPage.value >= imgCount.value) imgPage.value = 1
            else imgPage.value++
            emit('update:index', imgPage.value - 1)
        }

        const prevImage = () => {
            if (imgPage.value <= 1) imgPage.value = imgCount.value
            else imgPage.value--
            emit('update:index', imgPage.value - 1)
        }

        return { nextImage, prevImage, isBundle, selectedImg, imgPage, imgCount, getResizerUrl }
    },
})
