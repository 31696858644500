import { defineStore } from 'pinia'
import { useCollectionsStore } from '@/stores/collections.store'
import { useArtistsStore } from '@/stores/artists.store'
import { changeChain, login, rpc, selectedChain, Wallet } from 'vue-3-useeosiowallet'
import { useDropsStore } from '@/stores/drops.store'
import { fetchDotgemsData } from '@/helpers/fetch'
import { IArticle, useEditorialStore } from '@/stores/editorial.store'
import { useTemplatesStore } from '@/stores/templates.store'
import { useUserStore } from '@/stores/user.store'
import { useAssetStore } from '@/stores/assets.store'

export const useCoreStore = defineStore({
    id: 'coreStore',

    state: () => ({
        isInitialising: false,
        navigationMenu: [
            { target: 'PageHome', label: 'Drops', icon: 'CloudDownloadIcon', query: {} },
            {
                target: 'Collections',
                label: 'Collections',
                icon: 'CollectionIcon',
                query: { order: 'desc' },
            },
            { target: 'Editorial', label: 'Editorial', icon: 'BookOpenIcon', query: {} },
            { target: 'Artists', label: 'Artists', icon: 'UserGroupIcon', query: {} },
        ],
        showSidemenu: false,
        defaultOrder: 'desc' as 'desc' | 'asc',
        showLoginModal: false,
        isForSale: true,
        assetsPerPage: 12,
        delphiMedian: 0,
    }),

    getters: {
        tokenPriceUsd(): number {
            return this.delphiMedian / Math.pow(10, 4)
        },
    },

    actions: {
        async fetchTokenPriceUsd(): Promise<number> {
            const scope = {
                eos: 'eosusd',
                wax: 'waxpusd',
            }
            const chain = selectedChain.value.id
            const options = {
                code: 'delphioracle',
                json: true,
                limit: 1,
                scope: scope[chain as 'eos' | 'wax'],
                table: 'datapoints',
            }
            const result = await rpc.get_table_rows(options)
            if (!result.rows.length) throw new Error('Failed to fetch USD token price.')
            return (this.delphiMedian = result.rows[0].median)
        },

        // TODO refactor reset
        async resetDotgems(): Promise<void> {
            const collectionsStore = useCollectionsStore()
            collectionsStore.collectionIds = []
            collectionsStore.collections = []

            const templatesStore = useTemplatesStore()
            templatesStore.templates = []

            const assetStore = useAssetStore()
            assetStore.assets = []

            const userStore = useUserStore()
            userStore.assets = []

            const dropsStore = useDropsStore()
            dropsStore.dropIds = []
            dropsStore.drops = []

            const artistsStore = useArtistsStore()
            artistsStore.artistIds = []
            artistsStore.artists = []
        },

        async fetchDotgemsData(): Promise<void> {
            const chain = selectedChain.value.id
            const dropsStore = useDropsStore()
            const collectionsStore = useCollectionsStore()
            const artistsStore = useArtistsStore()
            const editorialStore = useEditorialStore()

            dropsStore.dropIds = (await fetchDotgemsData('drops', chain)) as number[]
            collectionsStore.collectionIds = (await fetchDotgemsData(
                'collections',
                chain,
            )) as string[]
            artistsStore.artistIds = (await fetchDotgemsData('artists', chain)) as string[]
            editorialStore.articles = (await fetchDotgemsData('articles', chain)) as IArticle[]
        },

        async attemptAutoLogin() {
            // Try Scatter for mobile wallets with Scatter protocol
            try {
                const success = await login(JSON.parse(Wallet.Scatter))
                if (success) return
            } catch (e) {
                console.log('No Scatter Wallet found.')
            }

            // Try autoLogin if previously logged in
            try {
                const autologin = localStorage.getItem('autologin')
                if (autologin) await login(JSON.parse(autologin) as Wallet)
            } catch (e) {
                console.warn('Autologin failed.', e)
                localStorage.removeItem('autologin')
            }
        },

        async initDotgems(): Promise<void> {
            this.isInitialising = true

            const chainId = localStorage.getItem('chain')
            if (chainId) await changeChain(JSON.parse(chainId))

            await this.resetDotgems()
            await this.fetchDotgemsData()

            const dropsStore = useDropsStore()
            const collectionsStore = useCollectionsStore()
            const artistsStore = useArtistsStore()

            await collectionsStore.fetchCollections()
            await artistsStore.fetchArtists()
            await dropsStore.fetchDrops()

            await this.fetchTokenPriceUsd()
            this.isInitialising = false

            await this.attemptAutoLogin()
        },
    },
})
