<template>
    <transition name="fade" mode="out-in">
        <TheLoadingScreen v-if="isInitialising" />
    </transition>

    <div class="fixed z-40 bottom-0 inset-x-0 pb-2 sm:pb-5 space-y-4">
        <BaseAlert v-for="alert in alerts" :key="alert.id" :alert="alert" />
    </div>

    <div v-if="!isInitialising">
        <TheMainHeader />

        <div class="pt-32 px-5">
            <router-view />
        </div>

        <TheMobileNavigation />
        <TheFooter />
    </div>

    <ModalLogin />
</template>

<script lang="ts">
import { computed, defineComponent, onMounted } from 'vue'
import TheMainHeader from '@/components/main/TheMainHeader.vue'
import { useCoreStore } from '@/stores/core.store'
import ModalLogin from '@/components/common/ModalLogin.vue'
import TheLoadingScreen from '@/components/main/TheLoadingScreen.vue'
import TheMobileNavigation from '@/components/main/TheMobileNavigation.vue'
import TheFooter from '@/components/main/TheFooter.vue'
import { useHead } from '@vueuse/head'
import BaseAlert from '@/components/base/BaseAlert.vue'
import { useAlertStore } from '@/stores/alert.store'

export default defineComponent({
    components: {
        BaseAlert,
        TheFooter,
        TheMobileNavigation,
        TheLoadingScreen,
        ModalLogin,
        TheMainHeader,
    },
    setup() {
        useHead({ title: 'dotGems | Curated NFTs on EOS and WAX' })

        const coreStore = useCoreStore()
        const alertStore = useAlertStore()
        const alerts = computed(() => alertStore.alerts)
        const isInitialising = computed(() => coreStore.isInitialising)

        const init = async () => {
            try {
                await coreStore.initDotgems()
            } catch (e) {
                console.error('Failed to initialse dotgems.', e)
            }
        }

        onMounted(async () => await init())

        return { isInitialising, alerts }
    },
})
</script>
