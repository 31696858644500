<template>
    <div
        class="
            absolute
            h-full
            w-full
            flex
            justify-center
            items-center
            z-50
            bg-gems-gray
            dark:bg-gems-dark
        "
    >
        <img
            src="./../../assets/images/dotgems-logo.png"
            alt="dotGems Logo"
            class="w-[100px] md:w-[400px] animate-pulse"
        />
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'TheLoadingScreen',
})
</script>

<style scoped></style>
