
import { defineComponent, PropType } from 'vue'
import { Listbox, ListboxButton, ListboxOptions, ListboxOption } from '@headlessui/vue'
import { CheckIcon, SelectorIcon } from '@heroicons/vue/solid'
import { IBaseSelect } from '@/types/app'

export default defineComponent({
    name: 'BaseSelect',
    components: { Listbox, ListboxButton, ListboxOptions, ListboxOption, CheckIcon, SelectorIcon },
    props: {
        modelValue: Object as PropType<IBaseSelect>,
        options: Array as PropType<IBaseSelect[]>,
    },
})
