<template>
    <div class="nav-bar border-b top-0 grid grid-cols-12">
        <div class="col-span-3 flex items-center md:hidden"><SelectChain /></div>
        <div
            class="col-span-6 md:col-span-3 mt-3 flex justify-center md:justify-start items-center"
        >
            <router-link :to="{ name: 'PageHome' }">
                <img
                    src="./../../assets/images/dotgems-logo.png"
                    alt="dotGems Logo"
                    class="w-[100px] md:w-[120px] lg:w-[200px]"
                />
            </router-link>
        </div>
        <div class="hidden col-span-6 md:flex items-center md:w-full">
            <div class="grid grid-cols-4 gap-4 w-full">
                <router-link
                    v-for="item in coreStore.navigationMenu"
                    :key="item.target"
                    :to="{ name: item.target, query: item.query }"
                    class="md:text-lg lg:text-xl text-center hover:font-bold"
                    active-class="font-bold"
                >
                    {{ item.label }}
                </router-link>
            </div>
        </div>
        <div class="col-span-3 flex justify-end">
            <div class="flex items-center">
                <SelectChain class="hidden md:block mr-5" />

                <UserMenu />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import SelectChain from '@/components/main/SelectChain.vue'
import { useCoreStore } from '@/stores/core.store'
import UserMenu from '@/components/main/UserMenu.vue'

export default defineComponent({
    name: 'TheMainHeader',
    components: { UserMenu, SelectChain },
    setup() {
        const coreStore = useCoreStore()

        return { coreStore }
    },
})
</script>
