<template>
    <div class="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
        <div :class="variantStyles['600']" class="p-2 rounded-lg shadow-lg sm:p-3">
            <div class="flex items-center justify-between flex-wrap">
                <div class="w-0 flex-1 flex items-center">
                    <span :class="variantStyles['800']" class="flex p-2 rounded-lg">
                        <SpeakerphoneIcon aria-hidden="true" class="h-6 w-6 text-white" />
                    </span>
                    <p class="ml-3 font-medium text-white truncate">
                        <span class="hidden md:inline">
                            {{ alert.title }}
                        </span>
                    </p>
                </div>

                <div
                    v-if="txHashButton"
                    class="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto"
                >
                    <a
                        :class="variantStyles['50']"
                        :href="txHashButton"
                        class="
                            flex
                            items-center
                            justify-center
                            px-4
                            py-2
                            rounded-full
                            shadow-sm
                            text-sm
                            font-medium
                            bg-white
                            dark:bg-black
                        "
                        target="_blank"
                    >
                        Show TX on Bloks.io
                    </a>
                </div>
                <div
                    v-if="alert.link"
                    class="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto"
                >
                    <a
                        :class="variantStyles['50']"
                        :href="alert.link.url"
                        class="
                            flex
                            items-center
                            justify-center
                            px-4
                            py-2
                            rounded-full
                            shadow-sm
                            text-sm
                            font-medium
                            bg-white
                            dark:bg-black
                        "
                        target="_blank"
                    >
                        {{ alert.link.label }}
                    </a>
                </div>
                <div class="order-2 flex-shrink-0 sm:order-3 sm:ml-2">
                    <button
                        :class="variantStyles['500']"
                        class="
                            -mr-1
                            flex
                            p-2
                            rounded-full
                            focus:outline-none focus:ring-2 focus:ring-white
                        "
                        type="button"
                        @click="remove"
                    >
                        <span class="sr-only">Dismiss</span>
                        <XIcon aria-hidden="true" class="h-6 w-6 text-white" />
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { computed, defineComponent, onMounted, PropType } from 'vue'
import { SpeakerphoneIcon, XIcon } from '@heroicons/vue/outline'
import { IAlert, useAlertStore } from '@/stores/alert.store'
import { selectedChain } from 'vue-3-useeosiowallet'

export default defineComponent({
    name: 'BaseAlert',
    props: {
        alert: { type: Object as PropType<IAlert>, required: true },
    },
    components: {
        SpeakerphoneIcon,
        XIcon,
    },
    setup(props) {
        const alertStore = useAlertStore()
        const variantStyles = computed(() => {
            switch (props.alert.variant) {
                case 'success':
                    return {
                        50: 'hover:bg-green-50',
                        500: 'hover:bg-green-500',
                        600: 'bg-green-600',
                        800: 'bg-green-800',
                    }
                case 'error':
                    return {
                        50: 'hover:bg-red-50',
                        500: 'hover:bg-red-500',
                        600: 'bg-red-600',
                        800: 'bg-red-800',
                    }
                default:
                    return {
                        50: 'hover:bg-blue-50',
                        500: 'hover:bg-blue-500',
                        600: 'bg-blue-600',
                        800: 'bg-blue-800',
                    }
            }
        })

        const txHashButton = computed(() => {
            if (!props.alert.txHash) return null

            const bloksUrl: string[] = ['https://']
            if (selectedChain.value.id !== 'eos') bloksUrl.push(`${selectedChain.value.id}.`)
            bloksUrl.push(`bloks.io/transaction/`)
            bloksUrl.push(props.alert.txHash)
            return bloksUrl.join('')
        })
        const remove = () => alertStore.removeAlert(props.alert.id)

        onMounted(async () => setTimeout(() => remove(), props.alert.showSeconds * 1000))
        return { variantStyles, remove, txHashButton }
    },
})
</script>
