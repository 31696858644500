
import { computed, defineComponent, onBeforeMount, onMounted, ref, watch } from 'vue'
import { useAssetStore } from '@/stores/assets.store'
import { IAsset } from '@/types/atomicapi/asset'
import { useCoreStore } from '@/stores/core.store'
import { IDataObject } from '@/types/app'
import GridHeader from '@/components/common/GridHeader.vue'
import { onBeforeRouteLeave, useRoute } from 'vue-router'
import EndOfPageTrigger from '@/components/common/EndOfPageTrigger.vue'
import CardAsset from '@/components/common/CardAsset.vue'
import SelectSort from '@/components/common/SelectSort.vue'
import { useHead } from '@vueuse/head'
import { selectedChain } from 'vue-3-useeosiowallet'
import { useTemplatesStore } from '@/stores/templates.store'

export default defineComponent({
    name: 'PageAssets',
    components: { SelectSort, CardAsset, EndOfPageTrigger, GridHeader },
    setup() {
        const route = useRoute()
        const routeQuery = computed(() => route.query)
        const collection_name = computed(() => route.query.collection_name ?? '')
        const template_id = computed(() => route.query.template_id ?? '')
        const template = computed(() => {
            const found = useTemplatesStore().templates.find(
                (x) => x.template_id === template_id.value,
            )
            if (found) return found
            return null
        })
        const coreStore = useCoreStore()
        const assetStore = useAssetStore()
        const isLoading = ref(false)
        const assets = ref<IAsset[]>([])
        const page = ref(1)
        const pagesMax = computed(() => assetStore.pagesMax)

        const sortOptions = [
            { id: 1, value: 'asset_id', label: 'Asset ID', disabled: false },
            { id: 2, value: 'minted', label: 'Minted', disabled: false },
            { id: 3, value: 'Transferred', label: 'Transferred', disabled: false },
            { id: 4, value: 'template_mint', label: 'Mint #', disabled: false },
        ]

        const fetchParams = (): IDataObject => {
            return {
                template_id: '',
                order: coreStore.defaultOrder,
                sort: 'asset_id',
                limit: coreStore.assetsPerPage.toString(),
                page: page.value.toString(),
                ...routeQuery.value,
            }
        }

        const loadData = async () => {
            assets.value = []
            isLoading.value = true
            page.value = 1
            const params = fetchParams()
            assets.value = await assetStore.fetchAssets(params)
            isLoading.value = false
        }

        const loadNextPage = async () => {
            if (assetStore.pagesMax || !hasMounted.value || isLoading.value) return
            page.value++
            const params = fetchParams()
            const data = await assetStore.fetchMoreAssets(params)
            assets.value = assets.value.concat(data)
        }

        onBeforeMount(async () => {
            await loadData()
            page.value = Math.ceil(assets.value.length / 12) ?? 1
        })

        const hasMounted = ref(false)
        onMounted(() => (hasMounted.value = true))

        const isLeavingRoute = ref(false)
        onBeforeRouteLeave(() => (isLeavingRoute.value = true))

        watch(routeQuery, async () => {
            if (isLeavingRoute.value) return
            await loadData()
        })

        useHead({
            title: computed(
                () =>
                    `dotGems | ${selectedChain.value.id.toUpperCase()} | Assets: ${
                        collection_name.value
                    }`,
            ),
        })

        return {
            collection_name,
            assets,
            isLoading,
            loadNextPage,
            sortOptions,
            hasMounted,
            pagesMax,
            template,
        }
    },
})
