<template>
    <router-link
        :to="{
            name: 'ArtistDetails',
            params: { account: artist.account },
        }"
        class="text-xl flex items-center hover:font-semibold mb-3"
        :class="{ 'font-semibold': isSelected }"
    >
        <img
            :src="`https://${selectedChain.id}.api.atomichub.io/v1/preview/avatar/${artist.account}`"
            alt="Artist Avatar"
            class="w-[32px] h-[32px] rounded-lg object-cover"
        />
        <span class="ml-2">{{ artist.account }}</span>
    </router-link>
    <div
        v-if="false"
        class="
            flex flex-col
            mt-2
            px-4
            pt-2
            pb-3
            space-y-2
            bg-gems-gray
            dark:bg-gems-dark
            md:bg-white md:dark:bg-black
            rounded-lg
        "
    >
        <router-link
            v-for="collection in artist.collections"
            :key="collection.collection_name"
            @click="closeSlideout"
            :to="{
                name: 'Collections',
                query: { collection_name: collection.collection_name },
            }"
            class="text-xl rounded-lg hover:font-semibold"
        >
            {{ collection.collection_name }}
        </router-link>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'
import { useRoute } from 'vue-router'
import { getResizerUrl } from '@/helpers/resizer'
import { IArtistsStore } from '@/stores/artists.store'
import { useCoreStore } from '@/stores/core.store'
import { selectedChain } from 'vue-3-useeosiowallet'

export default defineComponent({
    name: 'MenuArtistsItem',
    components: {},
    props: {
        artist: {
            type: Object as PropType<IArtistsStore>,
            required: true,
        },
    },
    setup(props) {
        const route = useRoute()
        const params = computed(() => route.params)
        const collections = computed(() => props.artist.collections)
        const isSelected = computed(() => props.artist.account === params.value.account)

        const closeSlideout = () => {
            useCoreStore().showSidemenu = false
        }

        return { collections, params, isSelected, getResizerUrl, closeSlideout, selectedChain }
    },
})
</script>
