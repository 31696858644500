
import { computed, defineComponent } from 'vue'
import { selectedChain } from 'vue-3-useeosiowallet'
import { useDropsStore } from '@/stores/drops.store'
import { useHead } from '@vueuse/head'
import BaseTabs from '@/components/base/BaseTabs.vue'
import DropLatest from '@/components/drop/DropLatest.vue'

export default defineComponent({
    name: 'PageHome',
    components: { DropLatest, BaseTabs },
    setup() {
        useHead({
            title: computed(() => `dotGems | ${selectedChain.value.id.toUpperCase()} | Drops`),
        })
        const dropStore = useDropsStore()

        return {
            dropStore,
        }
    },
})
