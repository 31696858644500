<template>
    <div class="flex items-center mb-10">
        <img
            :src="`https://${selectedChain.id}.api.atomichub.io/v1/preview/avatar/${account}`"
            alt="Artist Avatar"
            class="rounded-lg h-32 w-32 object-cover"
        />
        <h1 class="text-4xl font-bold ml-10">{{ account }}</h1>
    </div>
    <BaseTabs v-model="currentTab" :tabs="tabs">
        <section v-if="currentTab === 1">
            <div class="grid-cards">
                <router-link
                    v-for="collection in collections"
                    :key="collection.collection_name"
                    :to="{
                        name: 'Collections',
                        query: { collection_name: collection.collection_name },
                    }"
                >
                    <BaseCard :title="collection.name">
                        <template #image>
                            <div class="aspect-w-1 aspect-h-1">
                                <img
                                    :src="getResizerUrl(collection.img)"
                                    alt="Collection Image"
                                    class="rounded-lg object-contain"
                                />
                            </div>
                        </template>
                    </BaseCard>
                </router-link>
            </div>
        </section>
        <section v-if="currentTab === 2">
            <div class="prose" v-html="description"></div>
        </section>
    </BaseTabs>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from 'vue'
import { rpc, selectedChain } from 'vue-3-useeosiowallet'
import marked from 'marked'
import DOMPurify from 'dompurify'
import { useArtistsStore } from '@/stores/artists.store'
import BaseCard from '@/components/base/BaseCard.vue'
import { getResizerUrl } from '@/helpers/resizer'
import { useHead } from '@vueuse/head'
import BaseTabs from '@/components/base/BaseTabs.vue'

export default defineComponent({
    name: 'PageArtistDetails',
    components: { BaseTabs, BaseCard },
    props: {
        account: { type: String, required: true },
    },
    setup(props) {
        useHead({
            title: computed(
                () =>
                    `dotGems | ${selectedChain.value.id.toUpperCase()} | Artist: ${props.account}`,
            ),
        })

        const currentTab = ref(1)

        const tabs = [
            { id: 1, name: 'Collections' },
            { id: 2, name: 'Description' },
        ]

        const description = ref<string>('')

        const collections = computed(() => {
            const artist = useArtistsStore().artists.find((x) => x.account === props.account)
            if (artist) return artist.collections
            return []
        })

        const loadDescription = async () => {
            const options = {
                code: 'atomhubtools',
                json: true,
                limit: 1,
                lower_bound: 'description',
                scope: props.account,
                table: 'acctexts',
                upper_bound: 'description',
            }

            const result = await rpc.get_table_rows(options)

            if (!result.rows.length) return (description.value = '')

            const md = marked(result.rows[0].value)
            description.value = DOMPurify.sanitize(md)
        }
        onMounted(async () => {
            await loadDescription()
        })

        watch(props, async () => await loadDescription())
        return { description, collections, getResizerUrl, currentTab, tabs, selectedChain }
    },
})
</script>
