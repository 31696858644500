
import { defineComponent, ref } from 'vue'

export default defineComponent({
    name: 'SelectAmount',
    props: {
        modelValue: { type: Number, required: true },
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
        const input = ref(props.modelValue.toString())

        const increment = () => {
            input.value = (Number(input.value) + 1).toString()
            onChange()
        }

        const decrease = () => {
            input.value = (Number(input.value) - 1).toString()
            onChange()
        }

        const onChange = () => {
            if (!parseInt(input.value)) input.value = '1'
            input.value = parseInt(input.value).toString()
            emit('update:modelValue', Number(input.value))
        }

        return { input, onChange, increment, decrease }
    },
})
