<template>
    <div class="md:hidden">
        <div
            class="
                fixed
                bottom-[75px]
                z-30
                bg-white bg-opacity-60
                dark:bg-opacity-0
                border border-gems-gray
                dark:border-gems-dark
                right-3
                rounded-full
                backdrop-filter backdrop-blur-xl
                cursor-pointer
            "
            @click="coreStore.showSidemenu = true"
        >
            <div class="w-12 h-12 flex justify-center items-center">
                <FontAwesomeIcon fixed-width icon="bars"></FontAwesomeIcon>
            </div>
        </div>

        <BaseSlideout v-model="coreStore.showSidemenu">
            <router-view name="menu" />
        </BaseSlideout>
    </div>
    <div class="min-h-screen">
        <div class="flex md:space-x-10">
            <div class="hidden md:block">
                <nav
                    aria-label="Sidebar"
                    class="sticky w-[220px] max-h-[calc(100vh-150px)] overflow-y-auto top-32"
                >
                    <div class="mr-5">
                        <router-view name="menu" />
                    </div>
                </nav>
            </div>
            <main class="flex-grow">
                <router-view />
            </main>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import BaseModal from '@/components/base/BaseModal.vue'
import { useCoreStore } from '@/stores/core.store'
import BaseSlideout from '@/components/base/BaseSlideout.vue'

export default defineComponent({
    name: 'LayoutSidemenu',
    components: { BaseSlideout },
    setup() {
        const coreStore = useCoreStore()

        return { coreStore }
    },
})
</script>
