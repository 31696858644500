<template>
    <router-link
        :to="{
            name: 'Collections',
            query: { collection_name: collection.collection_name, order: 'desc' },
        }"
        class="text-xl flex hover:font-semibold mb-3"
        :class="{ 'font-semibold': isSelected }"
        @click="toggleExpanded()"
    >
        <img
            :src="`${getResizerUrl(collection.img)}`"
            alt="Template Image"
            class="w-[32px] h-[32px] rounded-lg object-cover"
        />
        <span v-if="isLoading" class="ml-2">loading</span>
        <span v-else class="ml-2">{{ collection.collection_name }}</span>
    </router-link>
    <div
        v-if="isSelected && isExpanded"
        class="
            flex flex-col
            mb-5
            mt-2
            px-4
            pt-2
            pb-3
            space-y-2
            bg-gems-gray
            dark:bg-gems-dark
            md:bg-white md:dark:bg-black
            rounded-lg
            shadow-lg
        "
    >
        <router-link
            v-for="schema in collection.schemas"
            :key="schema.schema_name"
            :class="{ 'font-semibold': schema.schema_name === routeQuery.schema_name }"
            class="text-xl rounded-lg hover:font-semibold"
            @click="closeSlideout"
            :to="{
                name: 'Collections',
                query: {
                    collection_name: collection.collection_name,
                    schema_name: schema.schema_name,
                    order: 'desc',
                },
            }"
        >
            {{ schema.schema_name }}
        </router-link>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, PropType, ref, watch } from 'vue'
import { useCollectionsStore } from '@/stores/collections.store'
import { useRoute } from 'vue-router'
import { ICollectionStore } from '@/types/atomicapi/collection'
import { getResizerUrl } from '@/helpers/resizer'
import { useCoreStore } from '@/stores/core.store'

export default defineComponent({
    name: 'MenuCollectionsItem',
    components: {},
    props: {
        collection: {
            type: Object as PropType<ICollectionStore>,
            required: true,
        },
    },
    setup(props) {
        const route = useRoute()
        const routeQuery = computed(() => route.query)
        const collectionStore = useCollectionsStore()
        const collections = computed(() => collectionStore.collections)
        const isLoading = ref<boolean>(false)
        const isExpanded = ref<boolean>(true)
        const isSelected = computed(
            () => props.collection.collection_name === routeQuery.value.collection_name,
        )
        const queryParams = computed(() => collectionStore.queryParams)
        const fetchSchemas = async () => {
            if (!isSelected.value) return

            const { collection_name } = props.collection
            isLoading.value = true

            try {
                if (collection_name)
                    await collectionStore.fetchSchemasByCollection(collection_name as string)
            } catch (e) {
                console.error('Failed to fetch schemas.', e)
            } finally {
                isLoading.value = false
            }
        }

        const toggleExpanded = () => {
            isExpanded.value = !isExpanded.value
        }

        onMounted(async () => {
            await fetchSchemas()
        })

        watch(routeQuery, async () => {
            await fetchSchemas()
        })

        watch(isSelected, (newVar) => {
            if (newVar) isExpanded.value = true
        })

        const closeSlideout = () => {
            useCoreStore().showSidemenu = false
        }

        return {
            collections,
            routeQuery,
            isLoading,
            isSelected,
            queryParams,
            getResizerUrl,
            closeSlideout,
            isExpanded,
            toggleExpanded,
        }
    },
})
</script>
