import { RouteRecordRaw } from 'vue-router'
import MenuCollections from '@/components/collections/MenuCollections.vue'
import PageForSale from '@/pages/forsale/PageForSale.vue'
import PageForSaleDetails from '@/pages/forsale/PageForSaleDetails.vue'
import LayoutSidemenu from '@/layouts/LayoutSidemenu.vue'

export const forSaleRoutes: Array<RouteRecordRaw> = [
    {
        path: '/for-sale/:sale_id',
        name: 'PageForSaleDetails',
        component: PageForSaleDetails,
        props: true,
    },
    {
        path: '/for-sale',
        // Set Layout Component
        component: LayoutSidemenu,
        // Set Pages using Layout
        children: [
            {
                path: '',
                name: 'ForSaleByTemplate',
                components: {
                    default: PageForSale,
                    menu: MenuCollections,
                },
            },
        ],
    },
]
