<template>
    <AssetMain v-if="sale" :asset="sale.assets[imgIndex]">
        <template v-if="images.length > 1" #media>
            <ImageBundle v-model:index="imgIndex" :images="images" />
        </template>
        <div class="text-center mt-10 text-sm text-gray-500">Price</div>
        <div class="flex justify-around font-semibold text-2xl">
            <div>
                {{ tokenPrice }}
                <span class="text-base text-gray-500">/ {{ usdPrice }}</span>
            </div>
        </div>

        <div class="flex justify-around mt-5">
            <div class="w-full max-w-sm text-center">
                <button
                    class="w-full py-2 bg-black text-white rounded-full font-semibold text-lg"
                    @click="buySale"
                >
                    Buy
                </button>
            </div>
        </div>
    </AssetMain>
</template>

<script lang="ts">
import { computed, defineComponent, onBeforeMount, ref } from 'vue'
import { useForSaleStore } from '@/stores/forsale.store'
import { ISale } from '@/types/atomicapi/sale'
import { convertPrice } from '@/helpers/pureFunctions'
import { useCoreStore } from '@/stores/core.store'
import { useHead } from '@vueuse/head'
import { selectedChain } from 'vue-3-useeosiowallet'
import AssetMain from '@/components/asset/AssetMain.vue'
import ImageBundle from '@/components/common/ImageBundle.vue'
import { useAlertStore } from '@/stores/alert.store'

export default defineComponent({
    name: 'PageForSaleDetails',
    components: { ImageBundle, AssetMain },
    props: {
        sale_id: { type: String, required: true },
    },
    setup(props) {
        useHead({
            title: computed(
                () => `dotGems | ${selectedChain.value.id.toUpperCase()} | Sale #${props.sale_id}`,
            ),
        })

        const coreStore = useCoreStore()
        const forSaleStore = useForSaleStore()
        const sale = ref<ISale | null>(null)

        const imgLoaded = ref(false)

        const imgIndex = ref(0)
        const images = computed(() => {
            if (!sale.value) return []
            return sale.value.assets.map((x) => x.data.img)
        })

        const tokenPrice = computed(() => {
            if (!sale.value) return '0'
            return convertPrice({
                amount: sale.value.price.amount,
                symbol: sale.value.price.token_symbol,
                prettify: true,
            })
        })

        const usdPrice = computed(() => {
            if (!sale.value) return '0'
            if (sale.value.listing_symbol === 'USD')
                return convertPrice({
                    amount: sale.value.listing_price,
                    symbol: 'USD',
                    prettify: true,
                })
            const amount =
                parseFloat(
                    convertPrice({
                        amount: sale.value.price.amount,
                        symbol: sale.value.price.token_symbol,
                    }),
                ) * coreStore.tokenPriceUsd
            return convertPrice({
                amount,
                symbol: 'USD',
                prettify: true,
                isDecimal: true,
            })
        })

        const buySale = async () => {
            if (!sale.value) return console.error('Sale Data not found.')

            try {
                const txHash = await forSaleStore.buy(sale.value)
                console.log('tx success!', txHash)
                await useAlertStore().addAlert({
                    variant: 'success',
                    title: 'Congrats! You are now the new owner.',
                    txHash,
                })
            } catch (e) {
                console.error('tx failed: ', e)
                await useAlertStore().addAlert({ variant: 'error', title: e.message })
            }
        }

        const loadData = async () => {
            sale.value = (await forSaleStore.fetchSale(props.sale_id)) as ISale
            console.log('image start loading', Date.now())
        }

        const imgHasLoaded = () => {
            imgLoaded.value = true
            console.log('image has loaded', Date.now())
        }

        onBeforeMount(async () => await loadData())

        return {
            sale,
            buySale,
            tokenPrice,
            imgLoaded,
            imgHasLoaded,
            usdPrice,
            images,
            imgIndex,
        }
    },
})
</script>
