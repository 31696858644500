import { defineStore } from 'pinia'
import { atomicApi } from '@/helpers/atomicApi'
import { ISale } from '@/types/atomicapi/sale'
import { IDataObject, PriceSymbol } from '@/types/app'
import { transact, username } from 'vue-3-useeosiowallet'
import { convertPrice } from '@/helpers/pureFunctions'
import { useCoreStore } from '@/stores/core.store'

export const useForSaleStore = defineStore({
    id: 'forSaleStore',

    state: () => ({
        sales: [] as ISale[],
        currentSelection: {
            template_id: '',
            min_price: '',
            max_price: '',
            min_template_mint: '',
            max_template_mint: '',
            order: 'desc',
            sort: 'price',
        } as IDataObject,
        pagesMax: false,
    }),

    actions: {
        async fetchSale(sale_id: string): Promise<ISale> {
            const found = this.sales.find((sale) => sale.sale_id === sale_id)
            if (found) return found

            return (await atomicApi('market', `sales/${sale_id}`)) as ISale
        },

        async fetchSales(params: IDataObject): Promise<ISale[]> {
            let isCurrentSelection = true
            for (const key in params) {
                if (key in this.currentSelection) {
                    if (this.currentSelection[key] !== params[key]) {
                        this.currentSelection[key] = params[key]
                        isCurrentSelection = false
                    }
                }
            }
            if (isCurrentSelection && this.sales.length) return this.sales

            this.sales = []
            this.pagesMax = false
            this.sales = (await atomicApi('market', 'sales', params)) as ISale[]

            this.pagesMax = this.sales.length < Number(params.limit)

            return this.sales
        },

        async fetchMoreSales(params: IDataObject): Promise<ISale[]> {
            const data = (await atomicApi('market', 'sales', params)) as ISale[]
            this.sales = this.sales.concat(data)
            this.pagesMax = data.length < Number(params.limit)
            return data
        },

        async buy(sale: ISale): Promise<string> {
            if (!username.value) {
                useCoreStore().showLoginModal = true
                throw new Error('Error: Login required to buy drop.')
            }

            const asset_ids_to_assert = sale.assets.map((a) => a.asset_id)

            const actionAssertSale = {
                account: 'atomicmarket',
                name: 'assertsale',
                data: {
                    sale_id: sale.sale_id,
                    asset_ids_to_assert,
                    listing_price_to_assert: convertPrice({
                        amount: sale.listing_price,
                        symbol: sale.listing_symbol as PriceSymbol,
                    }),
                    settlement_symbol_to_assert: `${sale.price.token_precision},${sale.price.token_symbol}`,
                },
            }

            const actionTransfer = {
                account: 'eosio.token',
                name: 'transfer',
                data: {
                    from: username.value,
                    to: 'atomicmarket',
                    quantity: convertPrice({
                        amount: sale.price.amount,
                        symbol: sale.price.token_symbol as PriceSymbol,
                    }),
                    memo: 'deposit',
                },
            }

            const actionPurchaseSale = {
                account: 'atomicmarket',
                name: 'purchasesale',
                data: {
                    buyer: username.value,
                    sale_id: sale.sale_id,
                    intended_delphi_median: sale.price.median ?? 0,
                    taker_marketplace: '',
                },
            }

            return await transact([actionAssertSale, actionTransfer, actionPurchaseSale])
        },
    },
})
