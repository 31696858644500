
import { computed, defineComponent, onMounted, onUnmounted, ref } from 'vue'
import { fetchJson } from '@/helpers/fetch'
import BaseWidget from '@/components/base/BaseWidget.vue'
import * as dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import { useHead } from '@vueuse/head'
import { selectedChain } from 'vue-3-useeosiowallet'
dayjs.extend(duration)

export default defineComponent({
    name: 'PageStats',
    components: { BaseWidget },
    setup() {
        useHead({
            title: computed(() => `dotGems | Statistics`),
        })
        const resizerStats = ref<any>(null)

        const downloadSpeed = computed(() => {
            const size = resizerStats.value.imagesDetails.originalSize.sum
            const time = resizerStats.value.imagesDetails.secondsDownloaded.sum

            return size / Math.pow(1024, 2) / time
        })

        const convertFileSize = (size: number, unit: string) => {
            // TODO fix toFixed when null issue for whole file
            let string = ''
            switch (unit) {
                case 'KB':
                    string = (size / Math.pow(1024, 1)).toFixed(2)
                    break
                case 'MB':
                    string = (size / Math.pow(1024, 2)).toFixed(2)
                    break
                case 'GB':
                    string = (size / Math.pow(1024, 3)).toFixed(2)
                    break
                default:
                    return size
            }
            return `${string} ${unit}`
        }

        const timeRemaining = computed(() => {
            const avgDl = resizerStats.value.imagesDetails.secondsDownloaded.avg
            const avgResizer = resizerStats.value.imagesDetails.secondsResized.avg
            const avgTotal = avgDl + avgResizer
            const inSeconds = avgTotal * resizerStats.value.images.notProcessed
            const duration = dayjs.duration(inSeconds, 's')

            if (inSeconds / 60 / 60 / 24 >= 1) return duration.format('D [d] H [h] m [min]')
            if (inSeconds / 60 / 60 >= 1) return duration.format('H [h] m [min]')
            if (inSeconds / 60 >= 1) return duration.format('m [min] s [sec]')
            return duration.format('s [sec]')
        })

        const loadStats = async () => {
            try {
                resizerStats.value = await fetchJson('https://resizer.semiofficial.io/stats')
            } catch (e) {
                console.error('Failed to load resizer stats: ', e)
            }
        }

        let interval: any = undefined

        onMounted(async () => {
            await loadStats()
            interval = setInterval(async () => {
                await loadStats()
            }, 2000)
        })

        onUnmounted(() => {
            clearInterval(interval)
        })

        return { resizerStats, convertFileSize, timeRemaining, downloadSpeed }
    },
})
