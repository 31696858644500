<template>
    <BaseModal v-model="coreStore.showLoginModal" title="Connect to Wallet">
        <div class="mt-10 text-center">
            <div class="grid grid-cols-3">
                <button
                    v-for="wallet in availableWallets"
                    :key="wallet"
                    type="button"
                    @click="selectWallet(wallet)"
                    class="flex flex-col items-center focus:outline-none"
                >
                    <span class="w-[50px] h-[50px] flex items-center">
                        <img
                            :src="require(`@/assets/images/${wallet}-logo.svg`)"
                            alt="Wallet Logo"
                        />
                    </span>

                    <span class="font-semibold text-lg mt-1 uppercase">{{ wallet }}</span>
                </button>
            </div>
        </div>
    </BaseModal>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import BaseModal from '@/components/base/BaseModal.vue'
import { useCoreStore } from '@/stores/core.store'
import { availableWallets, login, Wallet } from 'vue-3-useeosiowallet'
import { useAlertStore } from '@/stores/alert.store'

export default defineComponent({
    name: 'ModalLogin',
    components: { BaseModal },
    setup() {
        const coreStore = useCoreStore()
        const selectWallet = async (id: Wallet) => {
            coreStore.showLoginModal = false

            try {
                const user = await login(id)
                localStorage.setItem('autologin', JSON.stringify(id))
                await useAlertStore().addAlert({ title: `Welcome ${user}, you are now logged in.` })
            } catch (e) {
                console.error(e.message)
                await useAlertStore().addAlert({ variant: 'error', title: e.message })
            }
        }

        return { coreStore, availableWallets, selectWallet }
    },
})
</script>
