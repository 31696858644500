
import { computed, defineComponent, PropType, ref } from 'vue'
import { IDrop } from '@/types/atomicapi/drop'
import { getResizerUrl } from '@/helpers/resizer'
import { useTimestamp } from '@vueuse/core'
import dayjs from 'dayjs'
import { useDropsStore } from '@/stores/drops.store'
import SelectAmount from '@/components/common/SelectAmount.vue'
import { convertPrice, prettifyNumber } from '@/helpers/pureFunctions'
import { useAlertStore } from '@/stores/alert.store'
import { useCoreStore } from '@/stores/core.store'
import { selectedChain } from 'vue-3-useeosiowallet'

export default defineComponent({
    name: 'DropLatest',
    components: { SelectAmount },
    props: { drop: { type: Object as PropType<IDrop>, required: true } },
    setup(props) {
        const { timestamp } = useTimestamp()
        const amount = ref(1)
        const coreStore = useCoreStore()

        const buy = async (drop: IDrop) => {
            try {
                const txHash = await useDropsStore().buyDrop(drop, amount.value)
                await useAlertStore().addAlert({
                    variant: 'success',
                    title: 'Congrats! You are now the new owner.',
                    txHash,
                })
            } catch (e) {
                console.error(e)
                await useAlertStore().addAlert({ variant: 'error', title: e.message })
            }
        }

        const tokenPrice = computed(() => {
            const [price, symbol] = props.drop.listing_price.split(' ')
            const total = amount.value * Number(price)
            return `${prettifyNumber(total)} ${symbol}`
        })

        const usdPrice = computed(() => {
            const [price, symbol] = props.drop.listing_price.split(' ')
            if (symbol === 'USD') {
                const singlePrice = Number(price) / coreStore.tokenPriceUsd
                return convertPrice({
                    amount: singlePrice * amount.value,
                    symbol: selectedChain.value.id.toUpperCase(),
                    prettify: true,
                    isDecimal: true,
                })
            } else {
                const singlePrice = coreStore.tokenPriceUsd * Number(price)
                return convertPrice({
                    amount: singlePrice * amount.value,
                    symbol: 'USD',
                    prettify: true,
                    isDecimal: true,
                })
            }
        })

        const isFree = computed(() => !parseFloat(tokenPrice.value))

        const countdown = computed(() => {
            const current = dayjs(timestamp.value)
            const start = dayjs.unix(props.drop.start_time)
            const countdown = dayjs.duration(start.diff(current)).format('D[d] HH[h] mm[m] ss[s]')
            if (countdown.includes('-')) return null
            return countdown
        })
        const available = computed(() => {
            return props.drop.max_claimable - props.drop.current_claimed
        })

        return { buy, getResizerUrl, countdown, amount, available, tokenPrice, isFree, usdPrice }
    },
})
