import { defineStore } from 'pinia'
import { IDrop, IDropsTableRow } from '@/types/atomicapi/drop'
import { rpc, transact, username } from 'vue-3-useeosiowallet'
import { atomicApi } from '@/helpers/atomicApi'
import { ITemplate } from '@/types/atomicapi/template'
import { useCoreStore } from '@/stores/core.store'
import { convertPrice, sortArrayByString } from '@/helpers/pureFunctions'
import { useTimestamp } from '@vueuse/core'

const { timestamp } = useTimestamp()

export const useDropsStore = defineStore({
    id: 'dropsStore',

    state: () => ({
        dropIds: [] as number[],
        drops: [] as IDrop[],
        currentTab: 1,
        tabs: [
            { id: 1, name: 'All' },
            { id: 2, name: 'Upcoming' },
            { id: 3, name: 'Buy' },
            { id: 4, name: 'Claim' },
        ],
    }),

    getters: {
        filteredDrops(): IDrop[] {
            const upcoming = this.drops.filter((x) => x.start_time * 1000 > timestamp.value)
            const available = this.drops.filter(
                (x) =>
                    x.start_time * 1000 < timestamp.value &&
                    (x.end_time * 1000 > timestamp.value || !x.end_time) &&
                    x.max_claimable - x.current_claimed,
            )
            const buy = available.filter((x) => parseFloat(x.listing_price))
            const free = available.filter((x) => !parseFloat(x.listing_price))

            switch (this.currentTab) {
                case 2:
                    return upcoming
                case 3:
                    return buy
                case 4:
                    return free
                default:
                    return this.drops
            }
        },
    },

    actions: {
        async fetchDrops(): Promise<IDrop[]> {
            if (this.drops.length) return this.drops

            const drops: IDrop[] = []

            await Promise.all(
                await this.dropIds.map(async (id) => {
                    const data = {
                        code: 'atomicdropsx',
                        json: true,
                        limit: 1000,
                        scope: 'atomicdropsx',
                        table: 'drops',
                        lower_bound: id,
                        upper_bound: id,
                    }
                    const result = await rpc.get_table_rows(data)
                    if (result.rows.length !== 1) throw new Error(`Drop with ID ${id} not found.`)

                    const drop = result.rows[0] as IDropsTableRow
                    const template = (await atomicApi(
                        'assets',
                        `templates/${drop.collection_name}/${drop.assets_to_mint[0].template_id}`,
                    )) as ITemplate

                    drops.push({ ...drop, template })
                }),
            )

            return (this.drops = sortArrayByString(drops, 'drop_id', 'desc'))
        },

        async buyDrop(drop: IDrop, amount = 1): Promise<string> {
            if (!username.value) {
                useCoreStore().showLoginModal = true
                throw new Error('Error: Login required to buy drop.')
            }

            const [price, symbol] = drop.listing_price.split(' ')
            const total = Number(price) * amount
            const listing_price_to_assert = convertPrice({ amount: total, symbol, isDecimal: true })

            const assertdrop = {
                account: 'atomicdropsx',
                name: 'assertdrop',
                data: {
                    drop_id: drop.drop_id,
                    assets_to_mint_to_assert: drop.assets_to_mint,
                    listing_price_to_assert,
                    settlement_symbol_to_assert: drop.settlement_symbol,
                },
            }

            // TODO convert to token if price in USD
            const transfer = {
                account: 'eosio.token',
                name: 'transfer',
                data: {
                    from: username.value,
                    to: 'atomicdropsx',
                    quantity: listing_price_to_assert,
                    memo: 'deposit',
                },
            }

            const claimdrop = {
                account: 'atomicdropsx',
                name: 'claimdrop',
                data: {
                    claimer: username.value,
                    drop_id: drop.drop_id,
                    claim_amount: amount,
                    intended_delphi_median: 0,
                    referrer: '.gems',
                    country: 'XX',
                },
            }

            return await transact([assertdrop, transfer, claimdrop])
        },
    },
})
