<template>
    <Listbox :model-value="modelValue" @update:model-value="$emit('update:modelValue', $event)">
        <div class="relative">
            <ListboxButton
                class="
                    relative
                    w-full
                    py-2
                    pl-3
                    pr-10
                    text-left
                    bg-white
                    dark:bg-black
                    rounded-lg
                    shadow-md
                    cursor-default
                    focus:outline-none
                    text-sm
                "
            >
                <span class="block truncate">{{ modelValue.label ?? modelValue.value }}</span>
                <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <SelectorIcon class="w-5 h-5 text-gray-400" aria-hidden="true" />
                </span>
            </ListboxButton>
            <div class="relative z-20">
                <transition
                    enter-active-class="transition duration-200 ease-out"
                    enter-from-class="translate-y-1 opacity-0"
                    enter-to-class="translate-y-0 opacity-100"
                    leave-active-class="transition duration-150 ease-in"
                    leave-from-class="translate-y-0 opacity-100"
                    leave-to-class="translate-y-1 opacity-0"
                >
                    <ListboxOptions
                        class="
                            absolute
                            transform
                            w-full
                            p-1
                            mt-1
                            overflow-auto
                            dark:bg-black dark:bg-opacity-20
                            text-base
                            backdrop-filter backdrop-blur-xl
                            bg-white bg-opacity-40
                            rounded-md
                            shadow-lg
                            max-h-60
                            ring-1 ring-black ring-opacity-5
                            focus:outline-none
                            sm:text-sm
                        "
                    >
                        <ListboxOption
                            v-for="item in options"
                            v-slot="{ active, selected }"
                            :key="item.id"
                            :value="item"
                            :disabled="item.disabled"
                            as="template"
                        >
                            <li
                                :class="[
                                    active
                                        ? 'text-white bg-gems-blue rounded-lg'
                                        : 'text-gray-900 dark:text-gems-gray',
                                    'cursor-default select-none relative py-2 pl-10 pr-4',
                                    item.disabled ? 'opacity-25' : '',
                                ]"
                            >
                                <span
                                    :class="[selected ? 'font-semibold' : '', 'block truncate']"
                                    >{{ item.label ?? item.value }}</span
                                >
                                <span
                                    v-if="selected"
                                    class="
                                        absolute
                                        inset-y-0
                                        left-0
                                        flex
                                        items-center
                                        pl-3
                                        text-white
                                    "
                                >
                                    <CheckIcon class="w-5 h-5" aria-hidden="true" />
                                </span>
                            </li>
                        </ListboxOption>
                    </ListboxOptions>
                </transition>
            </div>
        </div>
    </Listbox>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { Listbox, ListboxButton, ListboxOptions, ListboxOption } from '@headlessui/vue'
import { CheckIcon, SelectorIcon } from '@heroicons/vue/solid'
import { IBaseSelect } from '@/types/app'

export default defineComponent({
    name: 'BaseSelect',
    components: { Listbox, ListboxButton, ListboxOptions, ListboxOption, CheckIcon, SelectorIcon },
    props: {
        modelValue: Object as PropType<IBaseSelect>,
        options: Array as PropType<IBaseSelect[]>,
    },
})
</script>
