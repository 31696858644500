
import { computed, defineComponent, onMounted, ref, watch } from 'vue'
import { useAssetStore } from '@/stores/assets.store'
import { IAsset } from '@/types/atomicapi/asset'
import { useHead } from '@vueuse/head'
import { selectedChain } from 'vue-3-useeosiowallet'
import AssetMain from '@/components/asset/AssetMain.vue'

export default defineComponent({
    name: 'PageAssetDetails',
    components: { AssetMain },
    props: {
        asset_id: String,
    },
    setup(props) {
        useHead({
            title: computed(
                () =>
                    `dotGems | ${selectedChain.value.id.toUpperCase()} | Asset #${props.asset_id}`,
            ),
        })
        const notFound = ref<boolean>(false)
        const asset = ref<IAsset | null>(null)

        const loadData = async () => {
            if (!props.asset_id) return (notFound.value = true)
            asset.value = await useAssetStore().fetchAsset(props.asset_id)
        }

        onMounted(async () => await loadData())
        watch(props, async () => await loadData())

        return { asset }
    },
})
