
import { defineComponent } from 'vue'
import {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
} from '@headlessui/vue'
import { XIcon } from '@heroicons/vue/outline'

export default defineComponent({
    name: 'BaseSlideout',
    components: {
        Dialog,
        DialogOverlay,
        DialogTitle,
        TransitionChild,
        TransitionRoot,
        XIcon,
    },
    props: {
        modelValue: { type: Boolean, default: false },
        title: { type: String, required: false },
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
        const close = () => {
            emit('update:modelValue', false)
        }
        return {
            close,
        }
    },
})
