import { defineStore } from 'pinia'
import { atomicApi } from '@/helpers/atomicApi'
import { IAsset } from '@/types/atomicapi/asset'
import { IDataObject } from '@/types/app'

export const useAssetStore = defineStore({
    id: 'assetStore',

    state: () => ({
        assets: [] as IAsset[],
        currentSelection: {
            template_id: '',
            order: 'desc',
            sort: 'asset_id',
        } as IDataObject,
        pagesMax: false,
    }),

    actions: {
        async fetchAsset(asset_id: string): Promise<IAsset> {
            const found = this.assets.find((asset) => asset.asset_id === asset_id)
            if (found) return found

            return (await atomicApi('assets', `assets/${asset_id}`)) as IAsset
        },

        async fetchAssets(params: IDataObject): Promise<IAsset[]> {
            let isCurrentSelection = true
            for (const key in params) {
                if (key in this.currentSelection) {
                    if (this.currentSelection[key] !== params[key]) {
                        this.currentSelection[key] = params[key]
                        isCurrentSelection = false
                    }
                }
            }
            if (isCurrentSelection && this.assets.length) return this.assets

            this.assets = []
            this.pagesMax = false
            this.assets = (await atomicApi('assets', 'assets', params)) as IAsset[]

            this.pagesMax = this.assets.length < Number(params.limit)

            return this.assets
        },

        async fetchMoreAssets(params: IDataObject): Promise<IAsset[]> {
            const data = (await atomicApi('assets', 'assets', params)) as IAsset[]
            this.assets = this.assets.concat(data)
            this.pagesMax = data.length < Number(params.limit)
            return data
        },
    },
})
