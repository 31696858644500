
import { computed, defineComponent, onMounted, ref } from 'vue'
import { IArticle, useEditorialStore } from '@/stores/editorial.store'
import { useHead } from '@vueuse/head'
import { selectedChain } from 'vue-3-useeosiowallet'

export default defineComponent({
    name: 'PageEditorial',
    components: {},
    setup() {
        useHead({
            title: computed(() => `dotGems | ${selectedChain.value.id.toUpperCase()} | Editorial`),
        })

        const editorialStore = useEditorialStore()
        const latest = ref<IArticle>()

        const articles = ref<IArticle[]>()

        onMounted(() => {
            const all = [...editorialStore.articles].reverse()
            latest.value = all.shift()
            articles.value = all
        })

        return { articles, latest }
    },
})
