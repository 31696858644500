import { RouteRecordRaw } from 'vue-router'
import LayoutHome from '@/layouts/LayoutHome.vue'
import PageStats from '@/pages/admin/PageStats.vue'

export const adminRoutes: Array<RouteRecordRaw> = [
    {
        path: '/admin',
        // Set Layout Component
        component: LayoutHome,
        // Set Pages using Layout
        children: [
            {
                path: '',
                name: 'Stats',
                component: PageStats,
            },
        ],
    },
]
