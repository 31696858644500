import { IQueryObject, PriceSymbol } from '@/types/app'

export const sanitizeObject = (object: IQueryObject): IQueryObject => {
    return Object.fromEntries(Object.entries(object).filter(([k, v]) => k && v))
}

export const prettifyNumber = (n: number | string, precision = 2): string => {
    const number = Number(n)
    if (number < 0.01) return '< 0.01'
    let result = number.toFixed(precision)
    if (number >= 1000) result = number.toFixed(0)
    return Number(result).toLocaleString('en')
}

export const convertPrice = (options: {
    amount: number | string
    symbol: string
    omitSymbol?: boolean
    toFixed?: number
    prettify?: boolean
    isDecimal?: boolean
}): string => {
    const { amount, symbol, omitSymbol, toFixed, prettify, isDecimal } = options
    const precisions = {
        EOS: 4,
        WAX: 8,
        USD: 2,
    }
    const precision = precisions[symbol as PriceSymbol]
    let result: number | string = Number(amount) / Math.pow(10, precision)
    if (isDecimal) result = Number(amount)
    result = result.toFixed(toFixed ?? precision)
    if (prettify) result = prettifyNumber(result)
    if (!omitSymbol) result = `${result} ${symbol}`

    return result
}

export const wait = async (seconds: number): Promise<void> => {
    return await new Promise((r) => setTimeout(r, seconds * 1000))
}

export const sortArrayByString = (
    array: any[],
    attr: string,
    order: 'asc' | 'desc' = 'asc',
): any[] => {
    return array.sort((a, b) => {
        let fa = a[attr]
        let fb = b[attr]

        if (typeof a[attr] === 'string') {
            fa = a[attr].toLowerCase()
            fb = b[attr].toLowerCase()
        }

        if (order === 'desc') {
            if (fa > fb) return -1
            if (fa < fb) return 1
            return 0
        }

        if (fa < fb) return -1
        if (fa > fb) return 1
        return 0
    })
}
