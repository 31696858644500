<template>
    <div class="px-3 md:px-5">
        <router-view />
    </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'

export default defineComponent({
    name: 'LayoutHome',
    setup() {
        const count = ref<number>(0)

        return { count }
    },
})
</script>
