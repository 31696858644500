
import { computed, defineComponent } from 'vue'
import { useCollectionsStore } from '@/stores/collections.store'
import MenuCollectionsItem from '@/components/collections/MenuCollectionsItem.vue'

export default defineComponent({
    name: 'MenuCollections',
    components: { MenuCollectionsItem },
    setup() {
        const collectionStore = useCollectionsStore()
        const collections = computed(() => collectionStore.collections)

        return { collections }
    },
})
