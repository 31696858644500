
import { computed, defineComponent, onMounted, PropType, ref, watch } from 'vue'
import { useCollectionsStore } from '@/stores/collections.store'
import { useRoute } from 'vue-router'
import { ICollectionStore } from '@/types/atomicapi/collection'
import { getResizerUrl } from '@/helpers/resizer'
import { useCoreStore } from '@/stores/core.store'

export default defineComponent({
    name: 'MenuCollectionsItem',
    components: {},
    props: {
        collection: {
            type: Object as PropType<ICollectionStore>,
            required: true,
        },
    },
    setup(props) {
        const route = useRoute()
        const routeQuery = computed(() => route.query)
        const collectionStore = useCollectionsStore()
        const collections = computed(() => collectionStore.collections)
        const isLoading = ref<boolean>(false)
        const isExpanded = ref<boolean>(true)
        const isSelected = computed(
            () => props.collection.collection_name === routeQuery.value.collection_name,
        )
        const queryParams = computed(() => collectionStore.queryParams)
        const fetchSchemas = async () => {
            if (!isSelected.value) return

            const { collection_name } = props.collection
            isLoading.value = true

            try {
                if (collection_name)
                    await collectionStore.fetchSchemasByCollection(collection_name as string)
            } catch (e) {
                console.error('Failed to fetch schemas.', e)
            } finally {
                isLoading.value = false
            }
        }

        const toggleExpanded = () => {
            isExpanded.value = !isExpanded.value
        }

        onMounted(async () => {
            await fetchSchemas()
        })

        watch(routeQuery, async () => {
            await fetchSchemas()
        })

        watch(isSelected, (newVar) => {
            if (newVar) isExpanded.value = true
        })

        const closeSlideout = () => {
            useCoreStore().showSidemenu = false
        }

        return {
            collections,
            routeQuery,
            isLoading,
            isSelected,
            queryParams,
            getResizerUrl,
            closeSlideout,
            isExpanded,
            toggleExpanded,
        }
    },
})
