
import { defineComponent } from 'vue'
import BaseModal from '@/components/base/BaseModal.vue'
import { useCoreStore } from '@/stores/core.store'
import { availableWallets, login, Wallet } from 'vue-3-useeosiowallet'
import { useAlertStore } from '@/stores/alert.store'

export default defineComponent({
    name: 'ModalLogin',
    components: { BaseModal },
    setup() {
        const coreStore = useCoreStore()
        const selectWallet = async (id: Wallet) => {
            coreStore.showLoginModal = false

            try {
                const user = await login(id)
                localStorage.setItem('autologin', JSON.stringify(id))
                await useAlertStore().addAlert({ title: `Welcome ${user}, you are now logged in.` })
            } catch (e) {
                console.error(e.message)
                await useAlertStore().addAlert({ variant: 'error', title: e.message })
            }
        }

        return { coreStore, availableWallets, selectWallet }
    },
})
