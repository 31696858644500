import { InitModule } from '@/types/app'
import {
    createRouter,
    createWebHistory,
    Router,
    RouteRecordRaw,
    RouterHistory,
    RouterScrollBehavior,
} from 'vue-router'
import { collectionsRoutes } from '@/routes/collections.routes'
import { homeRoutes } from '@/routes/home.routes'
import { editorialRoutes } from '@/routes/editorial.routes'
import { artistsRoutes } from '@/routes/artists.routes'
import { assetsRoutes } from '@/routes/assets.routes'
import { forSaleRoutes } from '@/routes/forsale.routes'
import { adminRoutes } from '@/routes/admin.routes'
import { userRoutes } from '@/routes/user.routes'

export const initRouter: InitModule = (app): Router => {
    const history: RouterHistory = createWebHistory(process.env.BASE_URL)
    const routes: Array<RouteRecordRaw> = [
        ...homeRoutes,
        ...collectionsRoutes,
        ...assetsRoutes,
        ...forSaleRoutes,
        ...editorialRoutes,
        ...artistsRoutes,
        ...adminRoutes,
        ...userRoutes,
    ]
    const scrollBehavior: RouterScrollBehavior = (to, from, savedPosition) => {
        if (savedPosition) return { top: savedPosition.top }
        return { top: 0 }
    }

    const router = createRouter({
        scrollBehavior,
        history,
        routes,
    })

    app.use(router)
    return router
}
