
import { computed, defineComponent, PropType } from 'vue'
import { IAsset } from '@/types/atomicapi/asset'
import AssetRarity from '@/components/common/AssetRarity.vue'

export default defineComponent({
    name: 'AssetTabDetails',
    components: { AssetRarity },
    props: {
        asset: { type: Object as PropType<IAsset>, required: true },
    },
    setup(props) {
        const maxSupply = computed(() => Number(props.asset.template.max_supply))

        return { maxSupply }
    },
})
