
import { computed, defineComponent, onMounted, PropType, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { ICollection } from '@/types/atomicapi/collection'
import { getResizerUrl } from '@/helpers/resizer'
import { useUserStore } from '@/stores/user.store'
import { useCoreStore } from '@/stores/core.store'

export default defineComponent({
    name: 'MenuUserItem',
    components: {},
    props: {
        account: { type: String, required: true },
        collection: {
            type: Object as PropType<ICollection>,
            required: true,
        },
    },
    setup(props) {
        const userStore = useUserStore()
        const route = useRoute()
        const routeQuery = computed(() => route.query)
        const isLoading = ref<boolean>(false)
        const isSelected = computed(
            () => props.collection.collection_name === routeQuery.value.collection_name,
        )
        const schemas = ref<any[]>([])

        const fetchSchemas = async () => {
            if (!isSelected.value) return

            const { collection_name } = props.collection
            isLoading.value = true

            try {
                if (collection_name) {
                    const data = await userStore.fetchSchemasByAccountAndCollection(
                        props.account,
                        collection_name,
                    )
                    schemas.value = data.schemas
                }
            } catch (e) {
                console.error('Failed to fetch schemas.', e)
            } finally {
                isLoading.value = false
            }
        }

        onMounted(async () => {
            await fetchSchemas()
        })

        watch(routeQuery, async () => {
            await fetchSchemas()
        })

        const closeSlideout = () => {
            useCoreStore().showSidemenu = false
        }

        return { schemas, routeQuery, isLoading, isSelected, getResizerUrl, closeSlideout }
    },
})
