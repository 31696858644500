import { selectedChain } from 'vue-3-useeosiowallet'
import { IDataObject } from '@/types/app'

export const atomicApi = async (
    target: 'assets' | 'market',
    route: string,
    query: IDataObject = {},
): Promise<unknown> => {
    const endpoint = `https://${selectedChain.value.id}.api.atomicassets.io/atomic${target}/v1/${route}`
    const url = new URL(endpoint)
    const sanitizedQuery = Object.fromEntries(
        Object.entries(query).filter(([k, v]) => k && v != ''),
    )

    url.search = new URLSearchParams(sanitizedQuery).toString()

    const raw = await fetch(url.toString())
    const result = await raw.json()
    if (!result.success) throw new Error(result)
    return result.data
}
