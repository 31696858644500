<template>
    <BaseModal v-model="showAnimation" size="full" prevent-close>
        <button class="absolute focus:outline-none z-10"></button>
        <video
            id="unpack-animation"
            autoplay
            width="100%"
            class="w-full rounded-lg object-contain z-20"
        >
            <source :src="require('@/assets/videos/testpack_w_sound.mp4')" type="video/mp4" />
            Your browser does not support the video tag.
        </video>
    </BaseModal>
</template>

<script lang="ts">
import { defineComponent, onMounted, onUnmounted, ref } from 'vue'
import BaseModal from '@/components/base/BaseModal.vue'

export default defineComponent({
    name: 'UnpackAnimation',
    components: { BaseModal },
    props: {
        asset_id: { type: String, required: true },
    },
    emits: ['onEnd'],
    setup(props, { emit }) {
        const showAnimation = ref(true)

        let interval = 0
        const animationInterval = () => {
            interval = setInterval(() => {
                const animation = document.getElementById('unpack-animation') as HTMLVideoElement
                if (!animation) return clearInterval(interval)

                const hasEnded = animation.ended
                if (!hasEnded) return

                clearInterval(interval)
                emit('onEnd')
                showAnimation.value = false
            }, 500)
        }

        onMounted(() => animationInterval())
        onUnmounted(() => clearInterval(interval))

        return { showAnimation }
    },
})
</script>
