import { RouteRecordRaw } from 'vue-router'
import MenuCollections from '@/components/collections/MenuCollections.vue'
import PageAssets from '@/pages/assets/PageAssets.vue'
import PageAssetDetails from '@/pages/assets/PageAssetDetails.vue'
import LayoutSidemenu from '@/layouts/LayoutSidemenu.vue'

export const assetsRoutes: Array<RouteRecordRaw> = [
    {
        path: '/asset/:asset_id',
        name: 'PageAssetDetails',
        component: PageAssetDetails,
        props: true,
    },
    {
        path: '/assets',
        // Set Layout Component
        component: LayoutSidemenu,
        // Set Pages using Layout
        children: [
            {
                path: '',
                name: 'AssetsByTemplate',
                components: {
                    default: PageAssets,
                    menu: MenuCollections,
                },
            },
        ],
    },
]
