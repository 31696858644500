
import { computed, defineComponent, PropType } from 'vue'
import { useRoute } from 'vue-router'
import { getResizerUrl } from '@/helpers/resizer'
import { IArtistsStore } from '@/stores/artists.store'
import { useCoreStore } from '@/stores/core.store'
import { selectedChain } from 'vue-3-useeosiowallet'

export default defineComponent({
    name: 'MenuArtistsItem',
    components: {},
    props: {
        artist: {
            type: Object as PropType<IArtistsStore>,
            required: true,
        },
    },
    setup(props) {
        const route = useRoute()
        const params = computed(() => route.params)
        const collections = computed(() => props.artist.collections)
        const isSelected = computed(() => props.artist.account === params.value.account)

        const closeSlideout = () => {
            useCoreStore().showSidemenu = false
        }

        return { collections, params, isSelected, getResizerUrl, closeSlideout, selectedChain }
    },
})
