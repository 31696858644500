<template>
    <div>
        <div v-if="latest" class="grid grid-cols-1 md:grid-cols-2 gap-6 lg:gap-16 mb-32">
            <router-link :to="{ name: 'Article', params: { id: latest.id } }">
                <div
                    :style="`background-image: url('${latest.imgUrl}`"
                    class="z-10 bg-center bg-no-repeat bg-cover rounded-lg aspect-w-3 aspect-h-2"
                ></div>
            </router-link>

            <div class="flex items-center">
                <article class="prose lg:prose-xl">
                    <h2 class="dark:!text-white">{{ latest.title }}</h2>

                    <p class="dark:text-gems-gray line-clamp-6">
                        {{ latest.content[0].text }}
                    </p>
                    <router-link
                        class="!font-semibold !text-gems-blue !hover:underline"
                        :to="{ name: 'Article', params: { id: latest.id } }"
                        >Continue reading ...</router-link
                    >
                </article>
            </div>
        </div>

        <div v-if="articles" class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-16">
            <article
                class="prose max-w-none md:prose-xl"
                v-for="article in articles"
                :key="article.title"
            >
                <router-link :to="{ name: 'Article', params: { id: article.id } }">
                    <div
                        :style="`background-image: url('${article.imgUrl}`"
                        class="
                            z-10
                            bg-center bg-no-repeat bg-cover
                            rounded-lg
                            aspect-w-2 aspect-h-1
                        "
                    ></div>
                </router-link>

                <h3 class="dark:!text-white !mb-2 !mt-3">{{ article.title }}</h3>

                <p class="dark:text-gems-gray line-clamp-4">
                    {{ article.content[0].text }}
                </p>
                <router-link
                    class="!font-semibold !text-gems-blue !hover:underline"
                    :to="{ name: 'Article', params: { id: article.id } }"
                    >Continue reading ...</router-link
                >
            </article>
        </div>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from 'vue'
import { IArticle, useEditorialStore } from '@/stores/editorial.store'
import { useHead } from '@vueuse/head'
import { selectedChain } from 'vue-3-useeosiowallet'

export default defineComponent({
    name: 'PageEditorial',
    components: {},
    setup() {
        useHead({
            title: computed(() => `dotGems | ${selectedChain.value.id.toUpperCase()} | Editorial`),
        })

        const editorialStore = useEditorialStore()
        const latest = ref<IArticle>()

        const articles = ref<IArticle[]>()

        onMounted(() => {
            const all = [...editorialStore.articles].reverse()
            latest.value = all.shift()
            articles.value = all
        })

        return { articles, latest }
    },
})
</script>
