<template>
    <div>
        <GridHeader :collection_name="collection_name" :hide-for-sale="true" />
        <div class="flex justify-between text-gray-400 uppercase font-bold mb-1">
            <div>Filter</div>
            <div>Sort</div>
        </div>
        <div class="flex flex-col-reverse xl:flex-row xl:justify-between xl:space-x-4">
            <div class="flex-grow xl:flex-grow-0 grid grid-cols-2 sm:grid-cols-4 gap-4 mb-5">
                <input
                    v-model="filter.min_issued_supply"
                    placeholder="Issued Supply Min"
                    type="text"
                />
                <input
                    v-model="filter.max_issued_supply"
                    placeholder="Issued Supply Max"
                    type="text"
                />
            </div>
            <div
                class="
                    flex-shrink
                    xl:min-w-[316px]
                    grid grid-cols-2
                    lg:grid-cols-4
                    xl:grid-cols-2
                    gap-4
                    mb-5
                "
            >
                <div class="hidden lg:col-span-2 lg:block xl:hidden"></div>
                <SelectRarity />
                <SelectSort />
            </div>
        </div>

        <hr class="border-0 border-t-2 border-white dark:border-black" />

        <div class="grid-cards mt-10">
            <router-link
                v-for="template in templates"
                :key="template.template_id"
                :to="{
                    name: coreStore.isForSale ? 'ForSaleByTemplate' : 'AssetsByTemplate',
                    query: {
                        collection_name: template.collection.collection_name,
                        template_id: template.template_id,
                        order: 'asc',
                    },
                }"
            >
                <BaseCard :title="template.name" :subtitle="`#${template.template_id}`">
                    <template #image>
                        <ImageBundle
                            v-if="!template.immutable_data.video"
                            :images="[template.immutable_data.img]"
                        />
                        <div v-else class="relative aspect-w-1 aspect-h-1">
                            <video autoplay loop class="rounded-lg object-contain" muted>
                                <source
                                    :src="`https://ipfs.io/ipfs/${template.immutable_data.video}`"
                                    type="video/mp4"
                                />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </template>

                    <div class="text-center">
                        <span
                            class="font-semibold uppercase"
                            :class="{
                                'text-violet-500': template.immutable_data.rarity === 'Rare',
                                'text-orange-400': template.immutable_data.rarity === 'Common',
                                'text-red-600': template.immutable_data.rarity === 'Ultra Rare',
                            }"
                        >
                            {{ template.immutable_data.rarity }}
                        </span>
                    </div>
                </BaseCard>
            </router-link>
        </div>

        <EndOfPageTrigger
            v-if="!isLoading && hasMounted && templates.length"
            @trigger="loadNextPage"
        />
        <div
            v-if="!templates.length && pagesMax"
            class="text-center text-2xl text-gray-400 pt-20 uppercase"
        >
            No Templates Found
        </div>

        <div v-if="!pagesMax" class="grid-cards">
            <div v-for="i in 12" :key="i">
                <div
                    class="aspect-w-1 aspect-h-1 bg-white bg-opacity-50 rounded-lg animate-pulse"
                ></div>
                <div class="h-[72px] bg-white bg-opacity-50 mt-2 rounded-lg animate-pulse"></div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, onBeforeMount, onMounted, ref, watch } from 'vue'
import BaseCard from '@/components/base/BaseCard.vue'
import { ITemplate } from '@/types/atomicapi/template'
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router'
import { useCoreStore } from '@/stores/core.store'
import { IDataObject } from '@/types/app'
import { useTemplatesStore } from '@/stores/templates.store'
import EndOfPageTrigger from '@/components/common/EndOfPageTrigger.vue'
import GridHeader from '@/components/common/GridHeader.vue'
import { useCollectionsStore } from '@/stores/collections.store'
import ImageBundle from '@/components/common/ImageBundle.vue'
import SelectRarity from '@/components/common/SelectRarity.vue'
import SelectSort from '@/components/common/SelectSort.vue'
import { useHead } from '@vueuse/head'
import { selectedChain } from 'vue-3-useeosiowallet'

export default defineComponent({
    name: 'PageTemplates',
    components: { SelectSort, SelectRarity, ImageBundle, GridHeader, EndOfPageTrigger, BaseCard },
    setup() {
        const router = useRouter()
        const route = useRoute()
        const routeQuery = computed(() => route.query)
        const collection_name = computed(() => route.query.collection_name ?? 'Our Newest Gems')
        const coreStore = useCoreStore()
        const templatesStore = useTemplatesStore()
        const isLoading = ref(true)
        const templates = ref<ITemplate[]>([])
        const page = ref(1)
        const pagesMax = computed(() => templatesStore.pagesMax)

        const filter = ref<{ [key: string]: string }>({
            min_issued_supply: '',
            max_issued_supply: '',
        })

        const fetchParams = (): IDataObject => {
            return {
                collection_name: '',
                schema_name: '',
                order: coreStore.defaultOrder,
                sort: 'created',
                'data.rarity': '',
                limit: coreStore.assetsPerPage.toString(),
                page: page.value.toString(),
                has_assets: 'true',
                collection_whitelist: useCollectionsStore().collectionIds.join(','),
                ...filter.value,
                ...routeQuery.value,
            }
        }

        const loadData = async () => {
            templates.value = []
            isLoading.value = true
            page.value = 1
            const params = fetchParams()
            templates.value = await templatesStore.fetchTemplates(params)
            isLoading.value = false
        }

        const loadNextPage = async () => {
            if (templatesStore.pagesMax || !hasMounted.value || isLoading.value) return
            page.value++
            const params = fetchParams()
            const data = await templatesStore.fetchMoreTemplates(params)
            templates.value = templates.value.concat(data)
        }

        onBeforeMount(async () => {
            for (const key in route.query) {
                if (Object.hasOwnProperty.call(route.query, key)) {
                    filter.value[key] = route.query[key] as string
                }
            }

            await loadData()
            page.value = Math.ceil(templates.value.length / 12) ?? 1
        })

        const hasMounted = ref(false)
        onMounted(() => (hasMounted.value = true))

        const isLeavingRoute = ref(false)
        onBeforeRouteLeave(() => (isLeavingRoute.value = true))

        watch(routeQuery, async () => {
            if (isLeavingRoute.value) return
            await loadData()
        })

        watch(filter.value, async (newFilter) => {
            if (!hasMounted.value) return
            // remove empty query params
            const query = Object.fromEntries(
                Object.entries(newFilter).filter(([k, v]) => k && v != ''),
            )

            await router.replace({ query })
            await loadData()
        })

        useHead({
            title: computed(
                () =>
                    `dotGems | ${selectedChain.value.id.toUpperCase()} | Collection: ${
                        collection_name.value
                    }`,
            ),
        })

        return {
            collection_name,
            templates,
            isLoading,
            coreStore,
            loadNextPage,
            hasMounted,
            pagesMax,
            filter,
        }
    },
})
</script>
