
import { computed, defineComponent, onBeforeMount } from 'vue'
import { useHead } from '@vueuse/head'
import { selectedChain } from 'vue-3-useeosiowallet'
import { useRouter } from 'vue-router'
import { useArtistsStore } from '@/stores/artists.store'

export default defineComponent({
    name: 'PageArtists',
    components: {},
    setup() {
        useHead({
            title: computed(() => `dotGems | ${selectedChain.value.id.toUpperCase()} | Artists`),
        })
        const randomAcc = computed(() => {
            var rand_index = Math.floor(Math.random() * useArtistsStore().artistIds.length)
            return useArtistsStore().artistIds[rand_index]
        })
        onBeforeMount(async () =>
            useRouter().replace({ name: 'ArtistDetails', params: { account: randomAcc.value } }),
        )
        return {}
    },
})
