
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'BaseWidget',
    props: {
        title: { type: String, required: true },
        value: { required: true },
    },
})
