
import { computed, defineComponent, onMounted } from 'vue'
import TheMainHeader from '@/components/main/TheMainHeader.vue'
import { useCoreStore } from '@/stores/core.store'
import ModalLogin from '@/components/common/ModalLogin.vue'
import TheLoadingScreen from '@/components/main/TheLoadingScreen.vue'
import TheMobileNavigation from '@/components/main/TheMobileNavigation.vue'
import TheFooter from '@/components/main/TheFooter.vue'
import { useHead } from '@vueuse/head'
import BaseAlert from '@/components/base/BaseAlert.vue'
import { useAlertStore } from '@/stores/alert.store'

export default defineComponent({
    components: {
        BaseAlert,
        TheFooter,
        TheMobileNavigation,
        TheLoadingScreen,
        ModalLogin,
        TheMainHeader,
    },
    setup() {
        useHead({ title: 'dotGems | Curated NFTs on EOS and WAX' })

        const coreStore = useCoreStore()
        const alertStore = useAlertStore()
        const alerts = computed(() => alertStore.alerts)
        const isInitialising = computed(() => coreStore.isInitialising)

        const init = async () => {
            try {
                await coreStore.initDotgems()
            } catch (e) {
                console.error('Failed to initialse dotgems.', e)
            }
        }

        onMounted(async () => await init())

        return { isInitialising, alerts }
    },
})
