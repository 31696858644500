<template>
    <BaseSelect v-model="selected" :options="options" class="w-full" />
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, PropType, ref, watch } from 'vue'
import BaseSelect from '@/components/base/BaseSelect.vue'
import { useRoute, useRouter } from 'vue-router'
import { IBaseSelect } from '@/types/app'
import { sanitizeObject } from '@/helpers/pureFunctions'

export default defineComponent({
    name: 'SelectQuery',
    components: { BaseSelect },
    props: {
        query: {
            type: String,
            required: true,
        },
        options: {
            type: Array as PropType<IBaseSelect[]>,
            required: true,
        },
        defaultOption: {
            type: Number,
            required: false,
            default: 0,
        },
    },
    setup(props) {
        const router = useRouter()
        const route = useRoute()
        const routeQuery = computed(() => route.query[props.query] as string | undefined)

        const selected = ref(props.options[props.defaultOption])

        const setQuery = (query: string | null) => {
            if (selected.value.value === query) return

            const value = props.options.find((x) => x.value === query)
            if (!value) return

            selected.value = value
        }

        onMounted(() => {
            if (typeof routeQuery.value !== 'string') return
            setQuery(routeQuery.value)
        })

        watch(routeQuery, (newVal) => {
            if (newVal === undefined) return (selected.value = props.options[0])
            setQuery(newVal)
        })

        watch(selected, (newVal) => {
            const sanitized = sanitizeObject({ ...route.query, [props.query]: newVal.value })
            router.replace({ query: sanitized })
        })

        return { selected }
    },
})
</script>
