import { InitModule } from '@/types/app'
import VueGtag from 'vue-gtag'

export const initVueGtag: InitModule = (app, router) => {
    app.use(
        VueGtag,
        {
            appName: 'dotGems',
            pageTrackerScreenviewEnabled: true,
            config: { id: 'G-FR2RJYDLXN' },
        },
        router,
    )
}
