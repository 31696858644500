
import { defineComponent } from 'vue'
import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue'

export default defineComponent({
    name: 'BaseSwitch',
    components: {
        Switch,
        SwitchGroup,
        SwitchLabel,
    },
    props: {
        modelValue: { type: Boolean, default: false },
        label: { type: String, default: '', required: false },
    },
})
