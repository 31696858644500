<template>
    <SwitchGroup>
        <div class="flex items-center">
            <SwitchLabel class="mr-3">{{ label }}</SwitchLabel>
            <Switch
                :model-value="modelValue"
                @update:model-value="$emit('update:modelValue', $event)"
                :class="modelValue ? 'bg-gems-blue' : 'bg-gems-blue bg-opacity-50'"
                class="
                    relative
                    inline-flex
                    items-center
                    h-6
                    transition
                    duration-200
                    rounded-full
                    w-11
                    focus:outline-none
                "
            >
                <span class="sr-only">{{ label ?? 'Toggle' }}</span>
                <span
                    :class="modelValue ? 'translate-x-6' : 'translate-x-1'"
                    class="
                        inline-block
                        w-4
                        h-4
                        transform
                        transition
                        duration-200
                        bg-white
                        rounded-full
                    "
                />
            </Switch>
        </div>
    </SwitchGroup>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue'

export default defineComponent({
    name: 'BaseSwitch',
    components: {
        Switch,
        SwitchGroup,
        SwitchLabel,
    },
    props: {
        modelValue: { type: Boolean, default: false },
        label: { type: String, default: '', required: false },
    },
})
</script>
