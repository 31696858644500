<template>
    <div>
        <router-link
            v-if="template"
            :to="{ name: 'Collections', query: { collection_name: collection_name } }"
            class="
                absolute
                text-gray-400
                hover:text-gems-blue
                transition
                duration-500
                mb-5
                flex
                items-center
                space-x-4
            "
        >
            <FontAwesomeIcon icon="chevron-left" />
            <img
                :src="`${getResizerUrl(collection.img)}`"
                alt="Artist Avatar"
                class="rounded-lg h-[32px] w-[32px] object-cover"
            />
            <div class="text-lg md:text-xl font-bold">{{ collection.name }}</div>
        </router-link>
        <div class="flex flex-col items-center md:flex-row mb-10 pt-20 md:pt-12">
            <img
                :src="`${getResizerUrl(image)}`"
                alt="Artist Avatar"
                class="rounded-lg h-48 w-48 object-cover md:mr-10 mb-4 md:mb-0"
            />
            <div>
                <h1 class="text-4xl font-bold text-center md:text-left">{{ title }}</h1>

                <div v-if="template" class="mt-7 flex items-center md:block">
                    <BaseSwitch v-if="!hideForSale" v-model="coreStore.isForSale" />
                    <h2 class="text-md font-medium uppercase text-gray-400 md:mt-3 ml-3 md:ml-0">
                        For Sale
                    </h2>
                </div>

                <p v-else :class="{ 'line-clamp-3': article }" class="my-2">
                    {{ description }}
                </p>
                <router-link
                    v-if="article && !template"
                    :to="{ name: 'Article', params: { id: article.id } }"
                    class="link"
                >
                    Read more ...
                </router-link>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { computed, defineComponent, PropType, watch } from 'vue'
import BaseSwitch from '@/components/base/BaseSwitch.vue'
import { useCoreStore } from '@/stores/core.store'
import { useRoute, useRouter } from 'vue-router'
import { useCollectionsStore } from '@/stores/collections.store'
import { useEditorialStore } from '@/stores/editorial.store'
import { getResizerUrl } from '@/helpers/resizer'
import { ITemplate } from '@/types/atomicapi/template'

export default defineComponent({
    name: 'GridHeader',
    components: { BaseSwitch },
    props: {
        collection_name: { type: String, required: true },
        template: {
            type: Object as PropType<ITemplate>,
            required: false,
            default: null,
        },
        hideForSale: { type: Boolean, required: false, default: false },
    },
    setup(props) {
        const router = useRouter()
        const route = useRoute()
        const coreStore = useCoreStore()

        const collection = computed(() =>
            useCollectionsStore().collections.find(
                (x) => x.collection_name === props.collection_name,
            ),
        )

        const title = computed(() => {
            if (props.template) return props.template.name
            if (!collection.value) return props.collection_name
            return collection.value.name
        })

        const image = computed(() => {
            if (props.template) return props.template.immutable_data.img
            if (!collection.value) return null
            return collection.value.img
        })

        const article = computed(() => {
            return useEditorialStore().getArticleByCollection(props.collection_name)
        })

        const description = computed(() => {
            if (article.value) return article.value.content[0].text

            if (!collection.value) return ''
            return collection.value.data.description
        })

        const isForSale = computed(() => coreStore.isForSale)

        watch(isForSale, async (newVar) => {
            if (newVar && route.name === 'AssetsByTemplate')
                return await router.replace({ name: 'ForSaleByTemplate', query: route.query })

            if (!newVar && route.name === 'ForSaleByTemplate')
                return await router.replace({ name: 'AssetsByTemplate', query: route.query })
        })

        return { collection, coreStore, title, description, article, image, getResizerUrl }
    },
})
</script>
