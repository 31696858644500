
import { computed, defineComponent, onMounted, watch } from 'vue'
import { useUserStore } from '@/stores/user.store'
import MenuUserItem from '@/components/user/MenuUserItem.vue'

export default defineComponent({
    name: 'MenuUser',
    components: { MenuUserItem },
    props: {
        account: { type: String, required: true },
    },
    setup(props) {
        const userStore = useUserStore()
        const collections = computed(() => userStore.data.collections)
        const account = computed(() => props.account)

        onMounted(async () => {
            await userStore.fetchCollectionsByUser(props.account)
        })

        watch(account, async () => await userStore.fetchCollectionsByUser(props.account))

        return { collections }
    },
})
