import { RouteRecordRaw } from 'vue-router'
import PageEditorial from '@/pages/editorial/PageEditorial.vue'
import LayoutHome from '@/layouts/LayoutHome.vue'
import PageArticle from '@/pages/editorial/PageArticle.vue'

export const editorialRoutes: Array<RouteRecordRaw> = [
    {
        path: '/editorial',
        // Set Layout Component
        component: LayoutHome,
        // Set Pages using Layout
        children: [
            {
                path: '',
                name: 'Editorial',
                component: PageEditorial,
            },
            {
                path: 'article/:id',
                name: 'Article',
                component: PageArticle,
                props: true,
            },
        ],
    },
]
