<template>
    <div class="relative">
        <div class="aspect-w-1 aspect-h-1">
            <img
                v-if="mediaIndex.type === 'img'"
                :src="`https://ipfs.io/ipfs/${images[mediaIndex.index]}`"
                alt="Template Image"
                class="cursor-pointer rounded-lg object-contain"
                @click="showOriginal = true"
            />
            <video
                v-if="mediaIndex.type === 'video'"
                autoplay
                class="rounded-lg object-contain"
                controls
                loop
            >
                <source
                    :src="`https://ipfs.io/ipfs/${ipfsMedia[mediaIndex.index]}`"
                    type="video/mp4"
                />
                Your browser does not support the video tag.
            </video>
        </div>
        <div v-if="showThumbnails" class="flex flex-wrap justify-center w-full mt-50">
            <img
                v-for="(img, index) in images"
                :key="img"
                :src="`https://ipfs.io/ipfs/${img}`"
                alt="NFT Image"
                class="
                    mt-4
                    mx-2
                    rounded-lg
                    cursor-pointer
                    w-[100px]
                    h-[100px]
                    object-contain
                    border-2 border-gray-400
                    hover:border-gems-blue
                "
                @click="setMediaIndex(index, 'img')"
            />
            <div
                v-for="(media, index) in ipfsMedia"
                :key="media"
                class="
                    flex
                    justify-center
                    items-center
                    w-[100px]
                    h-[100px]
                    bg-white
                    dark:bg-black
                    mt-4
                    mx-2
                    rounded-lg
                    cursor-pointer
                "
                @click="setMediaIndex(index, 'video')"
            >
                <PlayIcon class="w-12 h-12 text-gems-blue" />
            </div>
        </div>
    </div>

    <BaseModal v-model="showOriginal" size="auto" transparent>
        <div class="">
            <button class="focus:outline-none" @click="showOriginal = false">
                <img
                    :src="`https://ipfs.io/ipfs/${images[mediaIndex.index]}`"
                    alt="Template Image"
                    class="max-h-[90vh] object-contain"
                />
            </button>
        </div>
    </BaseModal>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from 'vue'
import { IAsset } from '@/types/atomicapi/asset'
import { PlayIcon } from '@heroicons/vue/solid'
import BaseModal from '@/components/base/BaseModal.vue'

interface IMediaIndex {
    index: number
    type: string
}

export default defineComponent({
    name: 'MediaView',
    components: { BaseModal, PlayIcon },
    props: {
        asset: { type: Object as PropType<IAsset>, required: true },
    },
    setup(props) {
        const showOriginal = ref(false)
        const images = computed(() =>
            props.asset.schema.format
                .filter((format) => format.name.includes('img'))
                .map((format) => format.name)
                .map((key) => props.asset.data[key])
                .filter((value) => !!value),
        )
        const ipfsMedia = computed(() => {
            return props.asset.schema.format
                .filter((format) => format.name.includes('video'))
                .map((format) => format.name)
                .map((key) => props.asset.data[key])
                .filter((value) => !!value)
        })
        const mediaIndex = ref<IMediaIndex>(
            ipfsMedia.value.length ? { index: 0, type: 'video' } : { index: 0, type: 'img' },
        )
        const setMediaIndex = (index: number, type: string) => {
            mediaIndex.value.index = index
            mediaIndex.value.type = type
        }

        const showThumbnails = computed(() => ipfsMedia.value.length + images.value.length > 1)

        return { mediaIndex, images, ipfsMedia, setMediaIndex, showOriginal, showThumbnails }
    },
})
</script>
