
import { computed, defineComponent, PropType } from 'vue'
import BaseCard from '@/components/base/BaseCard.vue'
import ImageBundle from '@/components/common/ImageBundle.vue'
import { IAsset } from '@/types/atomicapi/asset'
import AssetRarity from '@/components/common/AssetRarity.vue'

export default defineComponent({
    name: 'CardAsset',
    components: { AssetRarity, BaseCard, ImageBundle },
    props: {
        asset: { type: Object as PropType<IAsset>, required: true },
    },
    setup(props) {
        const rarity = computed(() =>
            props.asset.template ? props.asset.template.immutable_data.rarity : '',
        )

        return { rarity }
    },
})
