
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'BaseCard',
    props: {
        title: { type: String, required: false, default: '' },
        subtitle: { type: String, required: false, default: '' },
    },
})
