<template>
    <slot name="image" />
    <div class="mt-2 bg-white dark:bg-black rounded-lg py-3 px-4 shadow-lg">
        <slot name="title">
            <h2 class="text-xl font-semibold text-center line-clamp-1">{{ title }}</h2>
        </slot>
        <slot name="subtitle">
            <div class="text-sm text-gray-400 text-center">{{ subtitle }}</div>
        </slot>

        <slot></slot>
    </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'BaseCard',
    props: {
        title: { type: String, required: false, default: '' },
        subtitle: { type: String, required: false, default: '' },
    },
})
</script>
