<template>
    <div class="space-y-3">
        <div v-for="(value, key) in data" :key="key" class="grid grid-cols-12">
            <div class="col-span-4 md:col-span-2 font-semibold">{{ key }}</div>
            <div class="col-span-8 md:col-span-10 overflow-x-auto">
                {{ value }}
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { PropType } from 'vue'
import { IDataWithNameImg } from '@/types/atomicapi/main'

export default {
    name: 'AssetTabImmutableData',
    props: {
        data: { type: Object as PropType<IDataWithNameImg>, required: true },
    },
}
</script>
