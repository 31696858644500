
import { computed, defineComponent, onBeforeMount, onMounted, ref, watch } from 'vue'
import { ISale } from '@/types/atomicapi/sale'
import { useForSaleStore } from '@/stores/forsale.store'
import CardForSale from '@/components/forsale/CardForSale.vue'
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router'
import { useCoreStore } from '@/stores/core.store'
import { IDataObject } from '@/types/app'
import EndOfPageTrigger from '@/components/common/EndOfPageTrigger.vue'
import GridHeader from '@/components/common/GridHeader.vue'
import { selectedChain } from 'vue-3-useeosiowallet'
import SelectSort from '@/components/common/SelectSort.vue'
import { useHead } from '@vueuse/head'
import { useTemplatesStore } from '@/stores/templates.store'

export default defineComponent({
    name: 'PageForSale',
    components: { SelectSort, GridHeader, EndOfPageTrigger, CardForSale },
    setup() {
        const router = useRouter()
        const route = useRoute()
        const routeQuery = computed(() => route.query)
        const collection_name = computed(() => route.query.collection_name ?? '')
        const coreStore = useCoreStore()
        const forSaleStore = useForSaleStore()
        const isLoading = ref(false)
        const template_id = computed(() => route.query.template_id ?? '')
        const template = computed(() => {
            const found = useTemplatesStore().templates.find(
                (x) => x.template_id === template_id.value,
            )
            if (found) return found
            return null
        })
        const sales = ref<ISale[]>([])
        const page = ref(1)
        const pagesMax = computed(() => forSaleStore.pagesMax)

        const sortOptions = [
            { id: 1, value: 'price', label: 'Price', disabled: false },
            { id: 2, value: 'created', label: 'Date Listed', disabled: false },
            { id: 3, value: 'template_mint', label: 'Mint #', disabled: false },
        ]

        const filter = ref<{ [key: string]: string }>({
            min_price: '',
            max_price: '',
            min_template_mint: '',
            max_template_mint: '',
        })

        const fetchParams = (): IDataObject => {
            return {
                template_id: '',
                order: coreStore.defaultOrder,
                sort: 'price',
                state: '1',
                symbol: selectedChain.value.id.toUpperCase(),
                limit: coreStore.assetsPerPage.toString(),
                page: page.value.toString(),
                ...filter.value,
                ...routeQuery.value,
            }
        }

        const loadData = async () => {
            sales.value = []
            isLoading.value = true
            page.value = 1
            const params = fetchParams()
            sales.value = await forSaleStore.fetchSales(params)
            isLoading.value = false
        }

        const loadNextPage = async () => {
            if (forSaleStore.pagesMax || !hasMounted.value || isLoading.value) return
            page.value++
            const params = fetchParams()
            const data = await forSaleStore.fetchMoreSales(params)
            sales.value = sales.value.concat(data)
        }

        onBeforeMount(async () => {
            for (const key in route.query) {
                if (Object.hasOwnProperty.call(route.query, key)) {
                    filter.value[key] = route.query[key] as string
                }
            }

            await loadData()
            page.value = Math.ceil(sales.value.length / 12) ?? 1
        })

        const hasMounted = ref(false)
        onMounted(() => (hasMounted.value = true))

        const isLeavingRoute = ref(false)
        onBeforeRouteLeave(() => (isLeavingRoute.value = true))

        watch(routeQuery, async () => {
            if (isLeavingRoute.value) return
            await loadData()
        })

        watch(filter.value, async (newFilter) => {
            if (!hasMounted.value) return
            // remove empty query params
            const query = Object.fromEntries(
                Object.entries(newFilter).filter(([k, v]) => k && v != ''),
            )

            await router.replace({ query })
            await loadData()
        })

        useHead({
            title: computed(
                () =>
                    `dotGems | ${selectedChain.value.id.toUpperCase()} | For Sale: ${
                        collection_name.value
                    }`,
            ),
        })

        return {
            collection_name,
            sales,
            isLoading,
            loadNextPage,
            sortOptions,
            filter,
            hasMounted,
            pagesMax,
            template,
        }
    },
})
