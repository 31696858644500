
import { computed, defineComponent, PropType, watch } from 'vue'
import BaseSwitch from '@/components/base/BaseSwitch.vue'
import { useCoreStore } from '@/stores/core.store'
import { useRoute, useRouter } from 'vue-router'
import { useCollectionsStore } from '@/stores/collections.store'
import { useEditorialStore } from '@/stores/editorial.store'
import { getResizerUrl } from '@/helpers/resizer'
import { ITemplate } from '@/types/atomicapi/template'

export default defineComponent({
    name: 'GridHeader',
    components: { BaseSwitch },
    props: {
        collection_name: { type: String, required: true },
        template: {
            type: Object as PropType<ITemplate>,
            required: false,
            default: null,
        },
        hideForSale: { type: Boolean, required: false, default: false },
    },
    setup(props) {
        const router = useRouter()
        const route = useRoute()
        const coreStore = useCoreStore()

        const collection = computed(() =>
            useCollectionsStore().collections.find(
                (x) => x.collection_name === props.collection_name,
            ),
        )

        const title = computed(() => {
            if (props.template) return props.template.name
            if (!collection.value) return props.collection_name
            return collection.value.name
        })

        const image = computed(() => {
            if (props.template) return props.template.immutable_data.img
            if (!collection.value) return null
            return collection.value.img
        })

        const article = computed(() => {
            return useEditorialStore().getArticleByCollection(props.collection_name)
        })

        const description = computed(() => {
            if (article.value) return article.value.content[0].text

            if (!collection.value) return ''
            return collection.value.data.description
        })

        const isForSale = computed(() => coreStore.isForSale)

        watch(isForSale, async (newVar) => {
            if (newVar && route.name === 'AssetsByTemplate')
                return await router.replace({ name: 'ForSaleByTemplate', query: route.query })

            if (!newVar && route.name === 'ForSaleByTemplate')
                return await router.replace({ name: 'AssetsByTemplate', query: route.query })
        })

        return { collection, coreStore, title, description, article, image, getResizerUrl }
    },
})
