<template>
    <router-link
        :class="{ 'font-semibold': isSelected }"
        :to="{
            name: 'Profile',
            params: { account },
            query: {
                collection_name: collection.collection_name,
            },
        }"
        class="text-xl flex hover:font-semibold mb-3"
    >
        <img
            :src="`${getResizerUrl(collection.img)}`"
            alt="Template Image"
            class="w-[32px] h-[32px] rounded-lg object-cover"
        />
        <span v-if="isLoading" class="ml-2">loading</span>
        <span v-else class="ml-2">{{ collection.collection_name }}</span>
    </router-link>
    <div
        v-if="isSelected"
        class="
            flex flex-col
            mb-5
            mt-2
            px-4
            pt-2
            pb-3
            space-y-2
            bg-gems-gray
            dark:bg-gems-dark
            md:bg-white
            md:dark:bg-black
            rounded-lg
            shadow-lg
        "
    >
        <router-link
            v-for="schema in schemas"
            :key="schema.schema_name"
            :class="{ 'font-semibold': schema.schema_name === routeQuery.schema_name }"
            @click="closeSlideout"
            :to="{
                name: 'Profile',
                params: { account },
                query: {
                    collection_name: collection.collection_name,
                    schema_name: schema.schema_name,
                },
            }"
            class="text-xl rounded-lg hover:font-semibold"
        >
            {{ schema.schema_name }}
        </router-link>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, PropType, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { ICollection } from '@/types/atomicapi/collection'
import { getResizerUrl } from '@/helpers/resizer'
import { useUserStore } from '@/stores/user.store'
import { useCoreStore } from '@/stores/core.store'

export default defineComponent({
    name: 'MenuUserItem',
    components: {},
    props: {
        account: { type: String, required: true },
        collection: {
            type: Object as PropType<ICollection>,
            required: true,
        },
    },
    setup(props) {
        const userStore = useUserStore()
        const route = useRoute()
        const routeQuery = computed(() => route.query)
        const isLoading = ref<boolean>(false)
        const isSelected = computed(
            () => props.collection.collection_name === routeQuery.value.collection_name,
        )
        const schemas = ref<any[]>([])

        const fetchSchemas = async () => {
            if (!isSelected.value) return

            const { collection_name } = props.collection
            isLoading.value = true

            try {
                if (collection_name) {
                    const data = await userStore.fetchSchemasByAccountAndCollection(
                        props.account,
                        collection_name,
                    )
                    schemas.value = data.schemas
                }
            } catch (e) {
                console.error('Failed to fetch schemas.', e)
            } finally {
                isLoading.value = false
            }
        }

        onMounted(async () => {
            await fetchSchemas()
        })

        watch(routeQuery, async () => {
            await fetchSchemas()
        })

        const closeSlideout = () => {
            useCoreStore().showSidemenu = false
        }

        return { schemas, routeQuery, isLoading, isSelected, getResizerUrl, closeSlideout }
    },
})
</script>
