
import { defineComponent, PropType, ref } from 'vue'
import { IAsset } from '@/types/atomicapi/asset'
import MediaView from '@/components/common/MediaView.vue'
import AssetDetails from '@/components/asset/AssetTabDetails.vue'
import BaseTabs from '@/components/base/BaseTabs.vue'
import ImmutableData from '@/components/asset/AssetTabImmutableData.vue'
import AssetHeader from '@/components/asset/AssetHeader.vue'

export default defineComponent({
    name: 'AssetMain',
    components: { AssetHeader, ImmutableData, BaseTabs, AssetDetails, MediaView },
    props: {
        asset: { type: Object as PropType<IAsset>, required: true },
    },
    setup() {
        const currentTab = ref(1)

        const tabs = [
            { id: 1, name: 'Details' },
            { id: 2, name: 'Immutable Data' },
        ]
        return { currentTab, tabs }
    },
})
