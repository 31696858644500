
import { PropType } from 'vue'
import { IDataWithNameImg } from '@/types/atomicapi/main'

export default {
    name: 'AssetTabImmutableData',
    props: {
        data: { type: Object as PropType<IDataWithNameImg>, required: true },
    },
}
