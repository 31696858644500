
import { defineComponent, onMounted, ref, watch } from 'vue'
import { useElementVisibility } from '@vueuse/core'

export default defineComponent({
    name: 'EndOfPageTrigger',
    emits: ['trigger'],
    setup(props, { emit }) {
        const endOfPage = ref(null)
        const endOfPageIsVisible = useElementVisibility(endOfPage)

        onMounted(() => {
            if (!endOfPageIsVisible.value) return
            emit('trigger')
        })

        watch(endOfPageIsVisible, async (newValue) => {
            if (!newValue) return
            emit('trigger')
        })

        return { endOfPage }
    },
})
