
import { defineComponent, ref } from 'vue'
import BaseModal from '@/components/base/BaseModal.vue'
import { availableChains, changeChain, selectedChain } from 'vue-3-useeosiowallet'
import { useRouter } from 'vue-router'
import { useCoreStore } from '@/stores/core.store'

export default defineComponent({
    name: 'SelectChain',
    components: { BaseModal },
    setup() {
        const router = useRouter()
        const coreStore = useCoreStore()

        const isOpen = ref<boolean>(false)

        const selectChain = async (id: string) => {
            isOpen.value = false
            await changeChain(id)
            await router.push({ name: 'PageHome' })
            localStorage.setItem('chain', JSON.stringify(id))
            await coreStore.initDotgems()
        }

        return { isOpen, availableChains, selectChain, selectedChain }
    },
})
