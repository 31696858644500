import { defineStore } from 'pinia'
export interface IArticleContent {
    id: number
    text: string
}
export interface IArticleLink {
    id: number
    label: string
    url: string
}
export interface IArticle {
    id: number
    collection_name: string
    title: string
    imgUrl: string
    content: IArticleContent[]
    links: IArticleLink[]
}

export const useEditorialStore = defineStore({
    id: 'editorialStore',

    state: () => ({
        articles: [] as IArticle[],
    }),

    actions: {
        async getArticleById(id: number): Promise<IArticle> {
            const article = this.articles.find((a) => a.id === id)
            if (!article) throw new Error('Article ID not found.')

            return article
        },

        getArticleByCollection(collectionName: string): IArticle | null {
            const article = this.articles.find((a) => a.collection_name === collectionName)
            if (!article) return null

            return article
        },
    },
})
