<template>
    <div class="grid grid-cols-2 md:grid-cols-4 text-4xl gap-y-10 mb-10">
        <div class="text-center text-2xl">
            <span class="text-4xl font-semibold">{{ asset.template_mint }}</span>
            /
            {{ asset.template.issued_supply }}
            <div class="text-sm text-gray-500">Mint #</div>
        </div>
        <div class="text-center">
            <span v-if="maxSupply" class="font-semibold">{{ maxSupply }}</span>
            <FontAwesomeIcon v-else class="ml-1" icon="infinity"></FontAwesomeIcon>
            <div class="text-sm text-gray-500">Max Supply</div>
        </div>
        <div class="text-center">
            <FontAwesomeIcon
                :class="asset.is_burnable ? 'text-red-600' : 'text-gray-500'"
                icon="fire"
            ></FontAwesomeIcon>
            <div class="text-sm text-gray-500">Burnable</div>
        </div>
        <div class="text-center">
            <FontAwesomeIcon
                :class="asset.is_transferable ? 'text-green-600' : 'text-gray-500'"
                icon="exchange-alt"
            ></FontAwesomeIcon>
            <div class="text-sm text-gray-500">Transferable</div>
        </div>
    </div>

    <div class="grid grid-cols-2 md:grid-cols-4 gap-y-10 text-xl">
        <div class="text-center">
            <AssetRarity v-if="asset.data.rarity" :rarity="asset.data.rarity" />
            <div v-else>N/A</div>
            <div class="text-sm text-gray-500">Rarity</div>
        </div>
        <div class="text-center">
            <router-link
                :to="{
                    name: 'Collections',
                    query: { collection_name: asset.collection.collection_name },
                }"
                class="link text-xl"
            >
                {{ asset.collection.collection_name }}
            </router-link>
            <div class="text-sm text-gray-500">Collection</div>
        </div>

        <div class="text-center">
            <router-link
                :to="{
                    name: 'Collections',
                    query: {
                        collection_name: asset.collection.collection_name,
                        schema_name: asset.schema.schema_name,
                    },
                }"
                class="link text-xl"
            >
                {{ asset.schema.schema_name }}
            </router-link>
            <div class="text-sm text-gray-500">Schema</div>
        </div>

        <div class="text-center">
            <router-link
                :to="{
                    name: 'AssetsByTemplate',
                    query: {
                        collection_name: asset.collection.collection_name,
                        template_id: asset.template.template_id,
                    },
                }"
                class="link text-xl"
            >
                {{ asset.template.template_id }}
            </router-link>
            <div class="text-sm text-gray-500">Template #</div>
        </div>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'
import { IAsset } from '@/types/atomicapi/asset'
import AssetRarity from '@/components/common/AssetRarity.vue'

export default defineComponent({
    name: 'AssetTabDetails',
    components: { AssetRarity },
    props: {
        asset: { type: Object as PropType<IAsset>, required: true },
    },
    setup(props) {
        const maxSupply = computed(() => Number(props.asset.template.max_supply))

        return { maxSupply }
    },
})
</script>
