<template>
    <div class="relative">
        <div
            v-if="isBundle"
            class="absolute z-30 bg-black text-white p-2 rounded-2xl -right-2 text-xs -top-2"
        >
            {{ imgPage }} / {{ imgCount }}
        </div>
        <div class="z-10 rounded-lg aspect-w-1 aspect-h-1">
            <img
                :src="getResizerUrl(selectedImg)"
                alt="NFT Asset Image"
                class="rounded-lg object-contain"
            />
            <div v-if="isBundle" class="flex items-center text-white w-full h-full">
                <div @click.prevent="prevImage()" class="absolute">
                    <ChevronLeftIcon class="w-12 h-12" aria-hidden="true" />
                </div>
                <div @click.prevent="nextImage()" class="absolute right-0">
                    <ChevronRightIcon class="w-12 h-12" aria-hidden="true" />
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { computed, defineComponent, PropType, ref } from 'vue'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/solid'
import { getResizerUrl } from '@/helpers/resizer'

export default defineComponent({
    name: 'ImageBundle',
    components: { ChevronLeftIcon, ChevronRightIcon },
    props: {
        index: { type: Number },
        images: { type: Array as PropType<string[]>, required: true },
    },
    emits: ['update:index'],
    setup(props, { emit }) {
        const imgPage = ref(1)

        const imgCount = computed(() => props.images.length)
        const isBundle = computed(() => imgCount.value > 1)
        const selectedImg = computed(() => props.images[imgPage.value - 1])

        const nextImage = () => {
            if (imgPage.value >= imgCount.value) imgPage.value = 1
            else imgPage.value++
            emit('update:index', imgPage.value - 1)
        }

        const prevImage = () => {
            if (imgPage.value <= 1) imgPage.value = imgCount.value
            else imgPage.value--
            emit('update:index', imgPage.value - 1)
        }

        return { nextImage, prevImage, isBundle, selectedImg, imgPage, imgCount, getResizerUrl }
    },
})
</script>
