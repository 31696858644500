<template>
    <div class="nav-bar bottom-0 inset-x-0 border-t md:hidden pb-1">
        <div class="grid grid-cols-4">
            <router-link
                v-for="item in coreStore.navigationMenu"
                :key="item.target"
                :to="{ name: item.target, query: item.query }"
                class="flex flex-col items-center"
            >
                <component :is="item.icon" class="w-6 h-6" aria-hidden="true" />

                {{ item.label }}
            </router-link>
        </div>
    </div>
</template>
<script lang="ts">
import {
    BookOpenIcon,
    CollectionIcon,
    UserGroupIcon,
    CloudDownloadIcon,
} from '@heroicons/vue/outline'
import { useCoreStore } from '@/stores/core.store'
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'TheMobileNavigation',
    components: { CollectionIcon, UserGroupIcon, BookOpenIcon, CloudDownloadIcon },
    setup() {
        const coreStore = useCoreStore()

        return { coreStore }
    },
})
</script>
