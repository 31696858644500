<template>
    <BaseCard :title="title" :subtitle="`#${sale.sale_id}`">
        <template #image>
            <ImageBundle
                v-if="!sale.assets[imgIndex].data.video"
                v-model:index="imgIndex"
                :images="images"
            />
            <div v-else class="relative rounded-lg aspect-w-1 aspect-h-1">
                <video autoplay loop class="rounded-lg object-contain" muted>
                    <source
                        :src="`https://ipfs.io/ipfs/${sale.assets[imgIndex].data.video}`"
                        type="video/mp4"
                    />
                    Your browser does not support the video tag.
                </video>
            </div>
        </template>

        <div class="grid grid-cols-2">
            <div>
                <div class="text-sm text-gray-400">Mint</div>
                <span class="text-xl font-semibold"
                    >#{{ sale.assets[imgIndex].template_mint }}</span
                >
                /
                {{ sale.assets[imgIndex].template.issued_supply }}
            </div>
            <div class="text-right">
                <div class="text-sm text-gray-400">Price</div>
                <span class="mr-2">{{ sale.price.token_symbol }}</span>
                <span class="text-xl font-semibold">{{ tokenPrice }}</span>
            </div>
        </div>
    </BaseCard>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from 'vue'
import { ISale } from '@/types/atomicapi/sale'
import ImageBundle from '@/components/common/ImageBundle.vue'
import BaseCard from '@/components/base/BaseCard.vue'
import { convertPrice } from '@/helpers/pureFunctions'
import { PriceSymbol } from '@/types/app'

export default defineComponent({
    name: 'CardForSale',
    components: { BaseCard, ImageBundle },
    props: {
        sale: { type: Object as PropType<ISale>, required: true },
    },
    setup(props) {
        const imgIndex = ref(0)
        const assetsCount = computed(() => props.sale.assets.length)

        const title = computed(() => {
            if (assetsCount.value > 1) return `${assetsCount.value}  NFTs Bundle`
            return props.sale.assets[0].name
        })

        const images = computed(() => props.sale.assets.map((a) => a.data.img))

        const tokenPrice = computed(() => {
            const { amount, token_symbol } = props.sale.price
            return convertPrice({
                amount,
                symbol: token_symbol as PriceSymbol,
                omitSymbol: true,
                toFixed: 2,
                prettify: true,
            })
        })

        return { tokenPrice, title, images, imgIndex }
    },
})
</script>
