
import {
    BookOpenIcon,
    CollectionIcon,
    UserGroupIcon,
    CloudDownloadIcon,
} from '@heroicons/vue/outline'
import { useCoreStore } from '@/stores/core.store'
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'TheMobileNavigation',
    components: { CollectionIcon, UserGroupIcon, BookOpenIcon, CloudDownloadIcon },
    setup() {
        const coreStore = useCoreStore()

        return { coreStore }
    },
})
