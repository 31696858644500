<template>
    <TransitionRoot :show="modelValue" as="template">
        <Dialog
            :open="modelValue"
            as="div"
            class="fixed inset-0 overflow-hidden z-50"
            static
            @close="close"
        >
            <div class="absolute inset-0 overflow-hidden">
                <TransitionChild
                    as="template"
                    enter="duration-300 ease-out"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="duration-500 ease-in"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                >
                    <DialogOverlay class="absolute inset-0 backdrop-filter backdrop-blur-2xl" />
                </TransitionChild>

                <div class="fixed inset-y-0 right-0 max-w-full flex">
                    <TransitionChild
                        as="template"
                        enter="transform transition ease-in-out duration-500 sm:duration-700"
                        enter-from="translate-x-full"
                        enter-to="translate-x-0"
                        leave="transform transition ease-in-out duration-500 sm:duration-700"
                        leave-from="translate-x-0"
                        leave-to="translate-x-full"
                    >
                        <div class="w-screen max-w-[250px]">
                            <div
                                class="
                                    h-full
                                    flex flex-col
                                    py-6
                                    bg-white
                                    dark:bg-black
                                    shadow-xl
                                    overflow-y-scroll
                                "
                            >
                                <div class="px-4 sm:px-6">
                                    <div class="flex items-start justify-between">
                                        <DialogTitle class="text-lg font-medium text-gray-900">
                                            {{ title }}
                                        </DialogTitle>
                                        <div class="ml-3 h-7 flex items-center">
                                            <button
                                                class="
                                                    bg-white
                                                    rounded-md
                                                    text-gray-400
                                                    hover:text-gray-500
                                                    focus:outline-none
                                                    focus:ring-2
                                                    focus:ring-offset-2
                                                    focus:ring-indigo-500
                                                "
                                                @click="close"
                                            >
                                                <span class="sr-only">Close panel</span>
                                                <XIcon aria-hidden="true" class="h-6 w-6" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-6 relative flex-1 px-4 sm:px-6">
                                    <!-- Replace with your content -->
                                    <slot></slot>
                                    <!-- /End replace -->
                                </div>
                            </div>
                        </div>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
} from '@headlessui/vue'
import { XIcon } from '@heroicons/vue/outline'

export default defineComponent({
    name: 'BaseSlideout',
    components: {
        Dialog,
        DialogOverlay,
        DialogTitle,
        TransitionChild,
        TransitionRoot,
        XIcon,
    },
    props: {
        modelValue: { type: Boolean, default: false },
        title: { type: String, required: false },
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
        const close = () => {
            emit('update:modelValue', false)
        }
        return {
            close,
        }
    },
})
</script>
