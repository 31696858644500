
import { computed, defineComponent } from 'vue'
import MenuArtistsItem from '@/components/artists/MenuArtistsItem.vue'
import { useArtistsStore } from '@/stores/artists.store'

export default defineComponent({
    name: 'MenuArtists',
    components: { MenuArtistsItem },
    setup() {
        const artistsStore = useArtistsStore()
        const artists = computed(() => artistsStore.artists)

        return { artists }
    },
})
