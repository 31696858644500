
import { computed, defineComponent, PropType, ref } from 'vue'
import { IAsset } from '@/types/atomicapi/asset'
import { PlayIcon } from '@heroicons/vue/solid'
import BaseModal from '@/components/base/BaseModal.vue'

interface IMediaIndex {
    index: number
    type: string
}

export default defineComponent({
    name: 'MediaView',
    components: { BaseModal, PlayIcon },
    props: {
        asset: { type: Object as PropType<IAsset>, required: true },
    },
    setup(props) {
        const showOriginal = ref(false)
        const images = computed(() =>
            props.asset.schema.format
                .filter((format) => format.name.includes('img'))
                .map((format) => format.name)
                .map((key) => props.asset.data[key])
                .filter((value) => !!value),
        )
        const ipfsMedia = computed(() => {
            return props.asset.schema.format
                .filter((format) => format.name.includes('video'))
                .map((format) => format.name)
                .map((key) => props.asset.data[key])
                .filter((value) => !!value)
        })
        const mediaIndex = ref<IMediaIndex>(
            ipfsMedia.value.length ? { index: 0, type: 'video' } : { index: 0, type: 'img' },
        )
        const setMediaIndex = (index: number, type: string) => {
            mediaIndex.value.index = index
            mediaIndex.value.type = type
        }

        const showThumbnails = computed(() => ipfsMedia.value.length + images.value.length > 1)

        return { mediaIndex, images, ipfsMedia, setMediaIndex, showOriginal, showThumbnails }
    },
})
