import { RouteRecordRaw } from 'vue-router'
import PageHome from '@/pages/home/PageHome.vue'

export const homeRoutes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'PageHome',
        component: PageHome,
    },
]
