import { defineStore } from 'pinia'
import { atomicApi } from '@/helpers/atomicApi'
import { ICollection } from '@/types/atomicapi/collection'
import { useCollectionsStore } from '@/stores/collections.store'

export interface IArtistsStore {
    account: string
    collections: ICollection[]
}

export const useArtistsStore = defineStore({
    id: 'artistsStore',

    state: () => ({
        artistIds: [] as string[],
        artists: [] as IArtistsStore[],
    }),

    actions: {
        async fetchArtists(): Promise<void> {
            this.artists = this.artistIds.map((a) => {
                return { account: a, collections: [] }
            })
            await this.artistIds.map(async (a) => {
                await this.fetchCollectionsByArtist(a)
            })
        },

        async fetchCollectionsByArtist(artistName: string): Promise<void> {
            const index = this.artists.findIndex((x) => x.account === artistName)
            if (index < 0)
                throw new Error(
                    `Error: Artists collection fetch aborted. Artist not found: ${artistName}`,
                )

            if (this.artists[index].collections.length) return

            const collections = (await atomicApi('assets', 'collections', {
                author: artistName,
            })) as ICollection[]

            this.artists[index].collections = collections.filter((c) =>
                useCollectionsStore().collectionIds.find((id) => id === c.collection_name),
            )
        },
    },
})
