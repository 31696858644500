import { defineStore } from 'pinia'
import { atomicApi } from '@/helpers/atomicApi'
import { ICollection, ICollectionStore } from '@/types/atomicapi/collection'
import { ISchema } from '@/types/atomicapi/schema'
import { sortArrayByString } from '@/helpers/pureFunctions'

export const useCollectionsStore = defineStore({
    id: 'collectionsStore',

    state: () => ({
        collectionIds: [] as string[],
        collections: [] as ICollectionStore[],
        queryParams: {
            order: 'desc',
        },
    }),

    actions: {
        async fetchCollections(): Promise<void> {
            const data = (await atomicApi('assets', 'collections', {
                ids: this.collectionIds.join(','),
            })) as ICollection[]

            this.collections = data.map((c) => {
                return { ...c, schemas: [], templates: [] }
            })
            this.collections = sortArrayByString(this.collections, 'collection_name')
        },

        async fetchSchemasByCollection(collectionName: string): Promise<void> {
            const index = this.collections.findIndex((x) => x.collection_name === collectionName)
            if (index < 0)
                throw new Error(
                    `Fetch: Schema fetch aborted. Collection not available: ${collectionName}`,
                )

            if (this.collections[index].schemas.length) return

            this.collections[index].schemas = (await atomicApi('assets', 'schemas', {
                collection_name: collectionName,
            })) as ISchema[]
        },
    },
})
