<template>
    <div>
        <h2 class="text-lg font-bold uppercase text-gray-400 mb-2">Collections</h2>
        <div v-for="collection in collections" :key="collection.collection_name">
            <MenuCollectionsItem :collection="collection" />
        </div>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import { useCollectionsStore } from '@/stores/collections.store'
import MenuCollectionsItem from '@/components/collections/MenuCollectionsItem.vue'

export default defineComponent({
    name: 'MenuCollections',
    components: { MenuCollectionsItem },
    setup() {
        const collectionStore = useCollectionsStore()
        const collections = computed(() => collectionStore.collections)

        return { collections }
    },
})
</script>
