<template>
    <div v-if="resizerStats">
        <h2 class="text-4xl text-center font-bold mb-2">Statistics</h2>

        <div class="mb-10">
            <h2 class="text-2xl font-bold mb-2">Collections</h2>
            <div class="grid grid-cols-4 gap-10">
                <BaseWidget title="Total" :value="resizerStats.collections.total" />
                <BaseWidget title="EOS" :value="resizerStats.collections.eos" />
                <BaseWidget title="WAX" :value="resizerStats.collections.wax" />
                <BaseWidget title="Error" :value="0" />
            </div>
        </div>

        <div class="mb-10">
            <h2 class="text-2xl font-bold mb-2">Templates</h2>
            <div class="grid grid-cols-4 gap-10">
                <BaseWidget title="Total" :value="resizerStats.templates.total" />
                <BaseWidget title="EOS" :value="resizerStats.templates.eos" />
                <BaseWidget title="WAX" :value="resizerStats.templates.wax" />
                <BaseWidget title="Error" :value="resizerStats.templates.error" />
            </div>
        </div>

        <div class="mb-32">
            <h2 class="text-2xl font-bold mb-2">Images</h2>
            <div class="grid grid-cols-4 gap-10">
                <BaseWidget title="Total" :value="resizerStats.images.total" />
                <BaseWidget title="Processed" :value="resizerStats.images.processed" />
                <BaseWidget title="NOT Processed" :value="resizerStats.images.notProcessed" />
                <BaseWidget title="Error" :value="resizerStats.images.error" />
            </div>
        </div>

        <h2 class="text-4xl text-center font-bold mb-2">Image Processing</h2>

        <div v-if="resizerStats.images.notProcessed !== '0'" class="mb-10">
            <h2 class="text-2xl font-bold mb-2">Currently Processing</h2>
            <div class="grid grid-cols-2 gap-10">
                <BaseWidget title="Images Remaining" :value="resizerStats.images.notProcessed" />
                <BaseWidget title="Estimated Time left" :value="timeRemaining" />
            </div>
        </div>

        <div class="mb-10">
            <h2 class="text-2xl font-bold mb-2">Percentage Reduced</h2>
            <div class="grid grid-cols-3 gap-10">
                <BaseWidget
                    title="Average"
                    :value="`${resizerStats.imagesDetails.percentReduced.avg.toFixed(2)} %`"
                />
                <BaseWidget
                    title="Max"
                    :value="`${resizerStats.imagesDetails.percentReduced.max.toFixed(2)} %`"
                />
                <BaseWidget
                    title="Min"
                    :value="`${resizerStats.imagesDetails.percentReduced.min.toFixed(2)} %`"
                />
            </div>
        </div>

        <div class="mb-10">
            <h2 class="text-2xl font-bold mb-2">Time Download</h2>
            <div class="grid grid-cols-4 gap-10">
                <BaseWidget
                    title="Total"
                    :value="`${(resizerStats.imagesDetails.secondsDownloaded.sum / 60).toFixed(
                        0,
                    )} min`"
                />
                <BaseWidget
                    title="Average"
                    :value="`${resizerStats.imagesDetails.secondsDownloaded.avg.toFixed(2)} sec`"
                />
                <BaseWidget
                    title="Max"
                    :value="`${resizerStats.imagesDetails.secondsDownloaded.max.toFixed(2)} sec`"
                />
                <BaseWidget
                    title="Min"
                    :value="`${resizerStats.imagesDetails.secondsDownloaded.min.toFixed(2)} sec`"
                />
            </div>
        </div>

        <div class="mb-10">
            <h2 class="text-2xl font-bold mb-2">Time Resize Process</h2>
            <div class="grid grid-cols-4 gap-10">
                <BaseWidget
                    title="Total"
                    :value="`${(resizerStats.imagesDetails.secondsResized.sum / 60).toFixed(
                        0,
                    )} min`"
                />
                <BaseWidget
                    title="Average"
                    :value="`${resizerStats.imagesDetails.secondsResized.avg.toFixed(2)} sec`"
                />
                <BaseWidget
                    title="Max"
                    :value="`${resizerStats.imagesDetails.secondsResized.max.toFixed(2)} sec`"
                />
                <BaseWidget
                    title="Min"
                    :value="`${resizerStats.imagesDetails.secondsResized.min.toFixed(2)} sec`"
                />
            </div>
        </div>

        <div class="mb-10">
            <h2 class="text-2xl font-bold mb-2">Original Size</h2>
            <div class="grid grid-cols-4 gap-10">
                <BaseWidget
                    title="Total"
                    :value="convertFileSize(resizerStats.imagesDetails.originalSize.sum, 'GB')"
                />
                <BaseWidget
                    title="Average"
                    :value="convertFileSize(resizerStats.imagesDetails.originalSize.avg, 'MB')"
                />
                <BaseWidget
                    title="Max"
                    :value="convertFileSize(resizerStats.imagesDetails.originalSize.max, 'MB')"
                />
                <BaseWidget
                    title="Min"
                    :value="convertFileSize(resizerStats.imagesDetails.originalSize.min, 'KB')"
                />
            </div>
        </div>

        <div class="mb-10">
            <h2 class="text-2xl font-bold mb-2">Processed Size</h2>
            <div class="grid grid-cols-4 gap-10">
                <BaseWidget
                    title="Total"
                    :value="convertFileSize(resizerStats.imagesDetails.size.sum, 'GB')"
                />
                <BaseWidget
                    title="Average"
                    :value="convertFileSize(resizerStats.imagesDetails.size.avg, 'MB')"
                />
                <BaseWidget
                    title="Max"
                    :value="convertFileSize(resizerStats.imagesDetails.size.max, 'MB')"
                />
                <BaseWidget
                    title="Min"
                    :value="convertFileSize(resizerStats.imagesDetails.size.min, 'KB')"
                />
            </div>
        </div>

        <div class="mb-10">
            <h2 class="text-2xl font-bold mb-2">View Count</h2>
            <div class="grid grid-cols-4 gap-10">
                <BaseWidget title="Total" :value="resizerStats.imagesDetails.viewCount.sum" />
                <BaseWidget title="Average" :value="resizerStats.imagesDetails.viewCount.avg" />
                <BaseWidget title="Max" :value="resizerStats.imagesDetails.viewCount.max" />
                <BaseWidget title="Min" :value="resizerStats.imagesDetails.viewCount.min" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, onUnmounted, ref } from 'vue'
import { fetchJson } from '@/helpers/fetch'
import BaseWidget from '@/components/base/BaseWidget.vue'
import * as dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import { useHead } from '@vueuse/head'
import { selectedChain } from 'vue-3-useeosiowallet'
dayjs.extend(duration)

export default defineComponent({
    name: 'PageStats',
    components: { BaseWidget },
    setup() {
        useHead({
            title: computed(() => `dotGems | Statistics`),
        })
        const resizerStats = ref<any>(null)

        const downloadSpeed = computed(() => {
            const size = resizerStats.value.imagesDetails.originalSize.sum
            const time = resizerStats.value.imagesDetails.secondsDownloaded.sum

            return size / Math.pow(1024, 2) / time
        })

        const convertFileSize = (size: number, unit: string) => {
            // TODO fix toFixed when null issue for whole file
            let string = ''
            switch (unit) {
                case 'KB':
                    string = (size / Math.pow(1024, 1)).toFixed(2)
                    break
                case 'MB':
                    string = (size / Math.pow(1024, 2)).toFixed(2)
                    break
                case 'GB':
                    string = (size / Math.pow(1024, 3)).toFixed(2)
                    break
                default:
                    return size
            }
            return `${string} ${unit}`
        }

        const timeRemaining = computed(() => {
            const avgDl = resizerStats.value.imagesDetails.secondsDownloaded.avg
            const avgResizer = resizerStats.value.imagesDetails.secondsResized.avg
            const avgTotal = avgDl + avgResizer
            const inSeconds = avgTotal * resizerStats.value.images.notProcessed
            const duration = dayjs.duration(inSeconds, 's')

            if (inSeconds / 60 / 60 / 24 >= 1) return duration.format('D [d] H [h] m [min]')
            if (inSeconds / 60 / 60 >= 1) return duration.format('H [h] m [min]')
            if (inSeconds / 60 >= 1) return duration.format('m [min] s [sec]')
            return duration.format('s [sec]')
        })

        const loadStats = async () => {
            try {
                resizerStats.value = await fetchJson('https://resizer.semiofficial.io/stats')
            } catch (e) {
                console.error('Failed to load resizer stats: ', e)
            }
        }

        let interval: any = undefined

        onMounted(async () => {
            await loadStats()
            interval = setInterval(async () => {
                await loadStats()
            }, 2000)
        })

        onUnmounted(() => {
            clearInterval(interval)
        })

        return { resizerStats, convertFileSize, timeRemaining, downloadSpeed }
    },
})
</script>
