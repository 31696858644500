
import { defineComponent, ref } from 'vue'

export default defineComponent({
    name: 'LayoutHome',
    setup() {
        const count = ref<number>(0)

        return { count }
    },
})
