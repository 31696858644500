import { RouteRecordRaw } from 'vue-router'
import PageArtists from '@/pages/artists/PageArtists.vue'
import MenuArtists from '@/components/artists/MenuArtists.vue'
import PageArtistDetails from '@/pages/artists/PageArtistDetails.vue'
import LayoutSidemenu from '@/layouts/LayoutSidemenu.vue'

export const artistsRoutes: Array<RouteRecordRaw> = [
    {
        path: '/artists',
        // Set Layout Component
        component: LayoutSidemenu,
        // Set Pages using Layout
        children: [
            {
                path: '',
                name: 'Artists',
                components: {
                    default: PageArtists,
                    menu: MenuArtists,
                },
                props: true,
            },
            {
                path: ':account',
                name: 'ArtistDetails',
                components: {
                    default: PageArtistDetails,
                    menu: MenuArtists,
                },
                props: true,
            },
        ],
    },
]
