<template>
    <div class="flex items-center">
        <button
            :disabled="modelValue === 1"
            class="
                bg-black
                p-1
                rounded-lg
                w-6
                h-6
                text-white
                flex
                justify-center
                items-center
                disabled:bg-gray-400
                disabled:cursor-not-allowed
            "
            @click="decrease"
        >
            <FontAwesomeIcon icon="minus" />
        </button>

        <input v-model="input" class="w-12 text-center mx-3" type="text" @change="onChange" />
        <button
            class="bg-black p-1 rounded-lg w-6 h-6 text-white flex justify-center items-center"
            @click="increment"
        >
            <FontAwesomeIcon icon="plus" />
        </button>
    </div>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue'

export default defineComponent({
    name: 'SelectAmount',
    props: {
        modelValue: { type: Number, required: true },
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
        const input = ref(props.modelValue.toString())

        const increment = () => {
            input.value = (Number(input.value) + 1).toString()
            onChange()
        }

        const decrease = () => {
            input.value = (Number(input.value) - 1).toString()
            onChange()
        }

        const onChange = () => {
            if (!parseInt(input.value)) input.value = '1'
            input.value = parseInt(input.value).toString()
            emit('update:modelValue', Number(input.value))
        }

        return { input, onChange, increment, decrease }
    },
})
</script>
