<template>
    <div class="bg-white dark:bg-black rounded-lg text-center py-2">
        <div class="text-2xl font-semibold text-gems-blue">{{ value }}</div>
        <div>{{ title }}</div>
    </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'BaseWidget',
    props: {
        title: { type: String, required: true },
        value: { required: true },
    },
})
</script>
