import { defineStore } from 'pinia'

export interface IAlertLink {
    label: string
    url: string
}
export interface IAlert {
    id: number
    variant: string
    title: string
    showSeconds: number
    txHash?: string
    link?: IAlertLink
}

export const useAlertStore = defineStore({
    id: 'alertStore',

    state: () => ({
        alerts: [] as IAlert[],
    }),

    actions: {
        async addAlert(alert: {
            title: string
            variant?: 'info' | 'success' | 'error'
            txHash?: string
            link?: IAlertLink
            showSeconds?: number
        }) {
            this.alerts.push({
                id: this.alerts.length,
                variant: alert.variant ?? 'info',
                title: alert.title,
                txHash: alert.txHash,
                link: alert.link,
                showSeconds: alert.showSeconds ?? 8,
            })
        },
        async removeAlert(id: number) {
            const index = this.alerts.findIndex((x) => x.id === id)
            if (index < 0) return
            this.alerts.splice(index, 1)
        },
    },
})
