
import { computed, defineComponent, PropType, watch } from 'vue'
import { IBaseSelect } from '@/types/app'
import SelectQuery from '@/components/common/SelectQuery.vue'
import { useRoute } from 'vue-router'
import { useCoreStore } from '@/stores/core.store'

export default defineComponent({
    name: 'SelectSort',
    components: { SelectQuery },
    props: {
        sortOptions: {
            type: Array as PropType<IBaseSelect[]>,
            required: false,
            default: () => [],
        },
    },
    setup() {
        const orderOptions = [
            { id: 1, value: 'asc', label: 'Ascending', disabled: false },
            { id: 2, value: 'desc', label: 'Descending', disabled: false },
        ]
        const coreStore = useCoreStore()

        const defaultIndex = computed(() => {
            if (coreStore.defaultOrder === 'asc') return 0
            return 1
        })

        const route = useRoute()
        const orderQuery = computed(() => route.query.order ?? null)
        watch(orderQuery, (newVal) => {
            if (newVal === 'asc' || newVal === 'desc') coreStore.defaultOrder = newVal
        })

        return { orderOptions, defaultIndex }
    },
})
