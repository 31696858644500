<template>
    <Menu as="div" class="relative">
        <div>
            <slot name="button"> </slot>
        </div>

        <transition
            enter-active-class="transition duration-100 ease-out"
            enter-from-class="transform scale-95 opacity-0"
            enter-to-class="transform scale-100 opacity-100"
            leave-active-class="transition duration-75 ease-in"
            leave-from-class="transform scale-100 opacity-100"
            leave-to-class="transform scale-95 opacity-0"
        >
            <MenuItems
                class="
                    absolute
                    w-44
                    mt-2
                    origin-top-right
                    rounded-lg
                    shadow-lg
                    bg-white
                    dark:bg-black
                    outline-none
                "
                :class="right ? 'right-0' : 'left-0'"
            >
                <div class="p-2 space-y-1">
                    <slot></slot>
                </div>
            </MenuItems>
        </transition>
    </Menu>
</template>

<script>
import { Menu, MenuItems } from '@headlessui/vue'

export default {
    name: 'BaseDropdown',
    components: {
        Menu,
        MenuItems,
    },
    props: {
        button: { type: String, required: false, default: '' },
        right: { type: Boolean, required: false, default: false },
    },
}
</script>
