<template>
    <div
        :class="{
            'text-violet-500': rarity === 'Rare',
            'text-orange-400': rarity === 'Common',
            'text-red-600': rarity === 'Ultra Rare',
        }"
        class="font-semibold uppercase"
    >
        {{ rarity }}
    </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'AssetRarity',
    props: {
        rarity: { type: String, required: true },
    },
})
</script>
