<template>
    <GridHeader :collection_name="collection_name" :template="template" />
    <div class="flex justify-between text-gray-400 uppercase font-bold mb-1">
        <div>Filter</div>
        <div>Sort</div>
    </div>

    <div class="flex flex-col-reverse xl:flex-row xl:justify-between xl:space-x-4">
        <div class="flex-grow xl:flex-grow-0 grid grid-cols-2 sm:grid-cols-4 gap-4 mb-5">
            <input v-model="filter.min_price" type="text" placeholder="Price Min" />
            <input v-model="filter.max_price" type="text" placeholder="Price Max" />
            <input v-model="filter.min_template_mint" type="text" placeholder="Mint Min" />
            <input v-model="filter.max_template_mint" type="text" placeholder="Mint Max" />
        </div>

        <div
            class="
                flex-shrink
                xl:min-w-[316px]
                grid grid-cols-2
                lg:grid-cols-4
                xl:grid-cols-2
                gap-4
                mb-5
            "
        >
            <div class="hidden lg:col-span-2 lg:block xl:hidden"></div>
            <SelectSort :sort-options="sortOptions" />
        </div>
    </div>
    <hr class="border-0 border-t-2 border-white dark:border-black" />

    <div class="grid-cards mt-10">
        <router-link
            v-for="sale in sales"
            :key="sale.sale_id"
            :to="{ name: 'PageForSaleDetails', params: { sale_id: sale.sale_id } }"
        >
            <CardForSale :sale="sale" />
        </router-link>
    </div>

    <EndOfPageTrigger v-if="!isLoading && hasMounted && sales.length" @trigger="loadNextPage" />
    <div
        v-if="!sales.length && pagesMax"
        class="text-center text-2xl text-gray-400 pt-20 uppercase"
    >
        No Sales Found
    </div>

    <div v-if="!pagesMax" class="grid-cards">
        <div v-for="i in 12" :key="i">
            <div
                class="aspect-w-1 aspect-h-1 bg-white bg-opacity-50 rounded-lg animate-pulse"
            ></div>
            <div class="h-[72px] bg-white bg-opacity-50 mt-2 rounded-lg animate-pulse"></div>
        </div>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, onBeforeMount, onMounted, ref, watch } from 'vue'
import { ISale } from '@/types/atomicapi/sale'
import { useForSaleStore } from '@/stores/forsale.store'
import CardForSale from '@/components/forsale/CardForSale.vue'
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router'
import { useCoreStore } from '@/stores/core.store'
import { IDataObject } from '@/types/app'
import EndOfPageTrigger from '@/components/common/EndOfPageTrigger.vue'
import GridHeader from '@/components/common/GridHeader.vue'
import { selectedChain } from 'vue-3-useeosiowallet'
import SelectSort from '@/components/common/SelectSort.vue'
import { useHead } from '@vueuse/head'
import { useTemplatesStore } from '@/stores/templates.store'

export default defineComponent({
    name: 'PageForSale',
    components: { SelectSort, GridHeader, EndOfPageTrigger, CardForSale },
    setup() {
        const router = useRouter()
        const route = useRoute()
        const routeQuery = computed(() => route.query)
        const collection_name = computed(() => route.query.collection_name ?? '')
        const coreStore = useCoreStore()
        const forSaleStore = useForSaleStore()
        const isLoading = ref(false)
        const template_id = computed(() => route.query.template_id ?? '')
        const template = computed(() => {
            const found = useTemplatesStore().templates.find(
                (x) => x.template_id === template_id.value,
            )
            if (found) return found
            return null
        })
        const sales = ref<ISale[]>([])
        const page = ref(1)
        const pagesMax = computed(() => forSaleStore.pagesMax)

        const sortOptions = [
            { id: 1, value: 'price', label: 'Price', disabled: false },
            { id: 2, value: 'created', label: 'Date Listed', disabled: false },
            { id: 3, value: 'template_mint', label: 'Mint #', disabled: false },
        ]

        const filter = ref<{ [key: string]: string }>({
            min_price: '',
            max_price: '',
            min_template_mint: '',
            max_template_mint: '',
        })

        const fetchParams = (): IDataObject => {
            return {
                template_id: '',
                order: coreStore.defaultOrder,
                sort: 'price',
                state: '1',
                symbol: selectedChain.value.id.toUpperCase(),
                limit: coreStore.assetsPerPage.toString(),
                page: page.value.toString(),
                ...filter.value,
                ...routeQuery.value,
            }
        }

        const loadData = async () => {
            sales.value = []
            isLoading.value = true
            page.value = 1
            const params = fetchParams()
            sales.value = await forSaleStore.fetchSales(params)
            isLoading.value = false
        }

        const loadNextPage = async () => {
            if (forSaleStore.pagesMax || !hasMounted.value || isLoading.value) return
            page.value++
            const params = fetchParams()
            const data = await forSaleStore.fetchMoreSales(params)
            sales.value = sales.value.concat(data)
        }

        onBeforeMount(async () => {
            for (const key in route.query) {
                if (Object.hasOwnProperty.call(route.query, key)) {
                    filter.value[key] = route.query[key] as string
                }
            }

            await loadData()
            page.value = Math.ceil(sales.value.length / 12) ?? 1
        })

        const hasMounted = ref(false)
        onMounted(() => (hasMounted.value = true))

        const isLeavingRoute = ref(false)
        onBeforeRouteLeave(() => (isLeavingRoute.value = true))

        watch(routeQuery, async () => {
            if (isLeavingRoute.value) return
            await loadData()
        })

        watch(filter.value, async (newFilter) => {
            if (!hasMounted.value) return
            // remove empty query params
            const query = Object.fromEntries(
                Object.entries(newFilter).filter(([k, v]) => k && v != ''),
            )

            await router.replace({ query })
            await loadData()
        })

        useHead({
            title: computed(
                () =>
                    `dotGems | ${selectedChain.value.id.toUpperCase()} | For Sale: ${
                        collection_name.value
                    }`,
            ),
        })

        return {
            collection_name,
            sales,
            isLoading,
            loadNextPage,
            sortOptions,
            filter,
            hasMounted,
            pagesMax,
            template,
        }
    },
})
</script>
