import { setup } from 'vue-3-useeosiowallet'

const chains = [
    {
        id: 'eos',
        chainId: 'aca376f206b8fc25a6ed44dbdc66547c36c6c33e3a119ffbeaef943642f0e906',
        nodeUrl: 'https://eos.greymass.com',
    },
    {
        id: 'wax',
        chainId: '1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4',
        nodeUrl: 'https://wax.greymass.com',
    },
]

export const initEosioWallets = (): void => {
    void setup('dotGems', chains)
}
