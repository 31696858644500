import { InitModule } from '@/types/app'
import { initPinia } from './pinia'
import { initRouter } from '@/modules/router'
import { initEosioWallets } from '@/modules/eosioWallets'
import { initFontawesome } from '@/modules/fontawesome'
import { initVueGtag } from '@/modules/vuegtag'
import { Router } from 'vue-router'

export const initModules: InitModule = (app) => {
    const router = initRouter(app)
    initVueGtag(app, router as Router)
    initPinia(app)
    initFontawesome(app)
    initEosioWallets()
}
