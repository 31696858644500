
import { defineComponent } from 'vue'
import SelectChain from '@/components/main/SelectChain.vue'
import { useCoreStore } from '@/stores/core.store'
import UserMenu from '@/components/main/UserMenu.vue'

export default defineComponent({
    name: 'TheMainHeader',
    components: { UserMenu, SelectChain },
    setup() {
        const coreStore = useCoreStore()

        return { coreStore }
    },
})
