<template>
    <div v-if="article" class="flex justify-center">
        <article class="prose md:prose-xl">
            <h2 class="dark:!text-white">{{ article.title }}</h2>

            <img :src="article.imgUrl" alt="Article Cover Photo" class="rounded-lg" />

            <p class="dark:text-gems-gray" v-for="content in article.content" :key="content.id">
                {{ content.text }}
            </p>

            <h4 class="dark:!text-white">Links:</h4>
            <a
                class="!font-semibold !text-gems-blue !hover:underline mr-2"
                v-for="link in article.links"
                :key="link.label"
                :href="link.url"
                >{{ link.label }}</a
            >
        </article>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from 'vue'
import { IArticle, useEditorialStore } from '@/stores/editorial.store'
import { useHead } from '@vueuse/head'
import { selectedChain } from 'vue-3-useeosiowallet'

export default defineComponent({
    name: 'PageArticle',
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        useHead({
            title: computed(
                () => `dotGems | ${selectedChain.value.id.toUpperCase()} | Article #${props.id}`,
            ),
        })
        const editorialStore = useEditorialStore()
        const article = ref<IArticle | null>(null)
        onMounted(async () => {
            article.value = await editorialStore.getArticleById(Number(props.id))
        })

        return { article }
    },
})
</script>
