import { defineStore } from 'pinia'
import { atomicApi } from '@/helpers/atomicApi'
import { IAsset } from '@/types/atomicapi/asset'
import { IDataObject } from '@/types/app'
import { ICollection } from '@/types/atomicapi/collection'
import { useCollectionsStore } from '@/stores/collections.store'

interface IUserData {
    collections: { collection: ICollection; assets: string }[]
    templates: { assets: string; collection_name: string; template_id: string }[]
    assets: string
}

export const useUserStore = defineStore({
    id: 'userStore',

    state: () => ({
        assets: [] as IAsset[],
        data: { assets: '', collections: [], templates: [] } as IUserData,
        currentSelection: {
            owner: '',
            collection_name: '',
            schema_name: '',
            template_id: '',
            order: 'desc',
            sort: 'transferred',
        } as IDataObject,
        pagesMax: false,
    }),

    actions: {
        async fetchAssets(params: IDataObject): Promise<IAsset[]> {
            this.assets = (await atomicApi('assets', 'assets', params)) as IAsset[]

            this.pagesMax = this.assets.length < Number(params.limit)

            return this.assets
        },

        async fetchMoreAssets(params: IDataObject): Promise<IAsset[]> {
            const data = (await atomicApi('assets', 'assets', params)) as IAsset[]
            this.assets = this.assets.concat(data)
            this.pagesMax = data.length < Number(params.limit)
            return data
        },

        async fetchCollectionsByUser(account: string): Promise<void> {
            this.data = (await atomicApi('assets', `accounts/${account}`, {
                collection_whitelist: useCollectionsStore().collectionIds.join(','),
            })) as IUserData
        },

        async fetchSchemasByAccountAndCollection(
            account: string,
            collection_name: string,
        ): Promise<any> {
            return (await atomicApi('assets', `accounts/${account}/${collection_name}`)) as any
        },
    },
})
