export const fetchJson = async (url: string): Promise<unknown> => {
    return await (await fetch(url)).json()
}

export const fetchDotgemsData = async (
    data: 'collections' | 'drops' | 'artists' | 'articles',
    chain: string,
): Promise<unknown> => {
    return await fetchJson(
        `https://raw.githubusercontent.com/pingustar/dotgems-data/main/${chain}/${data}.json`,
    )
}
