import { defineStore } from 'pinia'
import { atomicApi } from '@/helpers/atomicApi'
import { IDataObject } from '@/types/app'
import { ITemplate } from '@/types/atomicapi/template'

export const useTemplatesStore = defineStore({
    id: 'templatesStore',

    state: () => ({
        templates: [] as ITemplate[],
        currentSelection: {
            collection_name: '',
            schema_name: '',
            min_issued_supply: '',
            max_issued_supply: '',
            order: 'desc',
            sort: 'created',
            'data.rarity': '',
        } as { [key: string]: string },
        pagesMax: false,
    }),

    actions: {
        async fetchTemplates(params: IDataObject): Promise<ITemplate[]> {
            let isCurrentSelection = true
            for (const key in params) {
                if (key in this.currentSelection) {
                    if (this.currentSelection[key] !== params[key]) {
                        this.currentSelection[key] = params[key]
                        isCurrentSelection = false
                    }
                }
            }
            if (isCurrentSelection && this.templates.length) return this.templates

            this.templates = []
            this.pagesMax = false
            this.templates = (await atomicApi('assets', 'templates', params)) as ITemplate[]

            this.pagesMax = this.templates.length < Number(params.limit)

            return this.templates
        },

        async fetchMoreTemplates(params: IDataObject): Promise<ITemplate[]> {
            const data = (await atomicApi('assets', 'templates', params)) as ITemplate[]
            this.templates = this.templates.concat(data)
            this.pagesMax = data.length < Number(params.limit)
            return data
        },
    },
})
