<template>
    <h1 class="text-center mb-6">Welcome to the .gems marketplace</h1>
    <h2 class="text-center mb-10 text-2xl">
        A place to discover curated NFT art and collectibles on WAX and EOS.
    </h2>
    <BaseTabs v-model="dropStore.currentTab" :tabs="dropStore.tabs">
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-16 gap-y-16">
            <DropLatest v-for="drop in dropStore.filteredDrops" :key="drop.drop_id" :drop="drop" />
        </div>
    </BaseTabs>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import { selectedChain } from 'vue-3-useeosiowallet'
import { useDropsStore } from '@/stores/drops.store'
import { useHead } from '@vueuse/head'
import BaseTabs from '@/components/base/BaseTabs.vue'
import DropLatest from '@/components/drop/DropLatest.vue'

export default defineComponent({
    name: 'PageHome',
    components: { DropLatest, BaseTabs },
    setup() {
        useHead({
            title: computed(() => `dotGems | ${selectedChain.value.id.toUpperCase()} | Drops`),
        })
        const dropStore = useDropsStore()

        return {
            dropStore,
        }
    },
})
</script>
