
import { defineComponent } from 'vue'
import SelectQuery from '@/components/common/SelectQuery.vue'

export default defineComponent({
    name: 'SelectRarity',
    components: { SelectQuery },
    setup() {
        const rarityOptions = [
            { id: 1, value: '', label: 'All Rarities' },
            { id: 2, value: 'Common' },
            { id: 3, value: 'Rare' },
            { id: 4, value: 'Ultra Rare' },
        ]

        return { rarityOptions }
    },
})
