<!-- This example requires Tailwind CSS v2.0+ -->
<template>
    <footer class="bg-white dark:bg-black mt-20">
        <div class="max-w-7xl mx-auto pt-12 pb-5 px-4 overflow-hidden sm:px-6 lg:px-8">
            <div class="flex justify-center items-center">
                <router-link :to="{ name: 'PageHome' }">
                    <img
                        alt="dotGems Logo"
                        class="w-[100px] md:w-[120px] lg:w-[200px]"
                        src="./../../assets/images/dotgems-logo.png"
                    />
                </router-link>
                <div class="mb-3">
                    <a
                        class="
                            text-white
                            bg-gems-blue
                            uppercase
                            font-semibold
                            py-2
                            px-5
                            text-sm
                            md:py-3 md:px-14
                            rounded-full
                            ml-5
                            md:ml-40
                        "
                        href="https://forms.monday.com/forms/4f7995d7277d876cdea679eba924d56a?r=use1"
                        target="_blank"
                        >Apply As An Artist</a
                    >
                </div>
            </div>

            <div class="mt-8 flex justify-center space-x-6">
                <a
                    v-for="item in navigation.social"
                    :key="item.name"
                    :href="item.href"
                    class="text-gray-400 hover:text-gray-500"
                >
                    <span class="sr-only">{{ item.name }}</span>
                    <component :is="item.icon" aria-hidden="true" class="h-6 w-6" />
                </a>
                <p class="text-base text-gray-400">&copy; 2021 .Gems - All rights reserved.</p>
            </div>
        </div>
    </footer>
</template>

<script lang="ts">
import { defineComponent, h } from 'vue'
import { useCoreStore } from '@/stores/core.store'

export default defineComponent({
    name: 'TheFooter',
    setup() {
        const navigation = {
            main: useCoreStore().navigationMenu,
            social: [
                {
                    name: 'Twitter',
                    href: 'https://twitter.com/dotgems_',
                    icon: defineComponent({
                        render: () =>
                            h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
                                h('path', {
                                    d: 'M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84',
                                }),
                            ]),
                    }),
                },
                {
                    name: 'Telegram',
                    href: 'https://t.me/dotGems',
                    icon: defineComponent({
                        render: () =>
                            h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
                                h('path', {
                                    'fill-rule': 'evenodd',
                                    d: 'M9.78 18.65l.28-4.23l7.68-6.92c.34-.31-.07-.46-.52-.19L7.74 13.3L3.64 12c-.88-.25-.89-.86.2-1.3l15.97-6.16c.73-.33 1.43.18 1.15 1.3l-2.72 12.81c-.19.91-.74 1.13-1.5.71L12.6 16.3l-1.99 1.93c-.23.23-.42.42-.83.42z',
                                    'clip-rule': 'evenodd',
                                }),
                            ]),
                    }),
                },
                {
                    name: 'Discord',
                    href: 'https://discord.com/invite/XhAKScPbZj',
                    icon: defineComponent({
                        render: () =>
                            h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
                                h('path', {
                                    'fill-rule': 'evenodd',
                                    d: 'M22 24l-5.25-5l.63 2H4.5A2.5 2.5 0 0 1 2 18.5v-15A2.5 2.5 0 0 1 4.5 1h15A2.5 2.5 0 0 1 22 3.5V24M12 6.8c-2.68 0-4.56 1.15-4.56 1.15c1.03-.92 2.83-1.45 2.83-1.45l-.17-.17c-1.69.03-3.22 1.2-3.22 1.2c-1.72 3.59-1.61 6.69-1.61 6.69c1.4 1.81 3.48 1.68 3.48 1.68l.71-.9c-1.25-.27-2.04-1.38-2.04-1.38S9.3 14.9 12 14.9s4.58-1.28 4.58-1.28s-.79 1.11-2.04 1.38l.71.9s2.08.13 3.48-1.68c0 0 .11-3.1-1.61-6.69c0 0-1.53-1.17-3.22-1.2l-.17.17s1.8.53 2.83 1.45c0 0-1.88-1.15-4.56-1.15m-2.07 3.79c.65 0 1.18.57 1.17 1.27c0 .69-.52 1.27-1.17 1.27c-.64 0-1.16-.58-1.16-1.27c0-.7.51-1.27 1.16-1.27m4.17 0c.65 0 1.17.57 1.17 1.27c0 .69-.52 1.27-1.17 1.27c-.64 0-1.16-.58-1.16-1.27c0-.7.51-1.27 1.16-1.27z',
                                    'clip-rule': 'evenodd',
                                }),
                            ]),
                    }),
                },
                {
                    name: 'Twitch',
                    href: 'https://www.twitch.tv/dotgems',
                    icon: defineComponent({
                        render: () =>
                            h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
                                h('path', {
                                    'fill-rule': 'evenodd',
                                    d: 'M11.64 5.93h1.43v4.28h-1.43m3.93-4.28H17v4.28h-1.43M7 2L3.43 5.57v12.86h4.28V22l3.58-3.57h2.85L20.57 12V2m-1.43 9.29l-2.85 2.85h-2.86l-2.5 2.5v-2.5H7.71V3.43h11.43z',
                                    'clip-rule': 'evenodd',
                                }),
                            ]),
                    }),
                },
                {
                    name: 'YouTube',
                    href: 'https://www.youtube.com/channel/UCokjLRgLgerCf5ijGYhp28w',
                    icon: defineComponent({
                        render: () =>
                            h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
                                h('path', {
                                    'fill-rule': 'evenodd',
                                    d: 'M10 15l5.19-3L10 9v6m11.56-7.83c.13.47.22 1.1.28 1.9c.07.8.1 1.49.1 2.09L22 12c0 2.19-.16 3.8-.44 4.83c-.25.9-.83 1.48-1.73 1.73c-.47.13-1.33.22-2.65.28c-1.3.07-2.49.1-3.59.1L12 19c-4.19 0-6.8-.16-7.83-.44c-.9-.25-1.48-.83-1.73-1.73c-.13-.47-.22-1.1-.28-1.9c-.07-.8-.1-1.49-.1-2.09L2 12c0-2.19.16-3.8.44-4.83c.25-.9.83-1.48 1.73-1.73c.47-.13 1.33-.22 2.65-.28c1.3-.07 2.49-.1 3.59-.1L12 5c4.19 0 6.8.16 7.83.44c.9.25 1.48.83 1.73 1.73z',
                                    'clip-rule': 'evenodd',
                                }),
                            ]),
                    }),
                },
                {
                    name: 'Instagram',
                    href: 'https://www.instagram.com/dotgems/',
                    icon: defineComponent({
                        render: () =>
                            h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
                                h('path', {
                                    'fill-rule': 'evenodd',
                                    d: 'M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z',
                                    'clip-rule': 'evenodd',
                                }),
                            ]),
                    }),
                },
            ],
        }

        return {
            navigation,
        }
    },
})
</script>
