
import { computed, defineComponent } from 'vue'
import {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
} from '@headlessui/vue'

export default defineComponent({
    name: 'BaseModal',
    components: { TransitionRoot, TransitionChild, Dialog, DialogOverlay, DialogTitle },
    props: {
        modelValue: { type: Boolean, default: false },
        title: { type: String, default: '', required: false },
        size: { type: String, default: 'md', required: false },
        preventClose: { type: Boolean, default: false, required: false },
        transparent: { type: Boolean, default: false, required: false },
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
        const close = () => {
            if (props.preventClose) return

            emit('update:modelValue', false)
        }
        const background = computed(() => {
            if (props.transparent) return ''
            return 'shadow-xl bg-white dark:bg-black'
        })
        const maxWidth = computed(() => {
            switch (props.size) {
                case 'sm':
                    return 'max-w-sm'
                case 'md':
                    return 'max-w-md'
                case 'lg':
                    return 'max-w-lg'
                case 'xl':
                    return 'max-w-xl'
                case '2xl':
                    return 'max-w-2xl'
                case 'full':
                    return 'max-w-full'
                case 'auto':
                    return 'w-auto'
                default:
                    return 'max-w-md'
            }
        })

        return { maxWidth, close, background }
    },
})
