<template>
    <GridHeader :collection_name="collection_name" :template="template" />
    <div class="flex justify-between text-gray-400 uppercase font-bold mb-1">
        <div></div>
        <div>Sort</div>
    </div>

    <div class="flex flex-col-reverse xl:flex-row xl:justify-between xl:space-x-4">
        <div class="flex-grow xl:flex-grow-0 grid grid-cols-2 sm:grid-cols-4 gap-4 mb-5"></div>

        <div
            class="
                flex-shrink
                xl:min-w-[316px]
                grid grid-cols-2
                lg:grid-cols-4
                xl:grid-cols-2
                gap-4
                mb-5
            "
        >
            <div class="hidden lg:col-span-2 lg:block xl:hidden"></div>
            <SelectSort :sort-options="sortOptions" />
        </div>
    </div>
    <hr class="border-0 border-t-2 border-white dark:border-black" />

    <div class="grid-cards mt-10">
        <CardAsset v-for="asset in assets" :key="asset.asset_id" :asset="asset" />
    </div>

    <EndOfPageTrigger v-if="!isLoading && hasMounted && assets.length" @trigger="loadNextPage" />
    <div
        v-if="!assets.length && pagesMax"
        class="text-center text-2xl text-gray-400 pt-20 uppercase"
    >
        No Sales Found
    </div>

    <div v-if="!pagesMax" class="grid-cards">
        <div v-for="i in 12" :key="i">
            <div
                class="
                    aspect-w-1 aspect-h-1
                    bg-white
                    dark:bg-black
                    bg-opacity-50
                    rounded-lg
                    animate-pulse
                "
            ></div>
            <div class="h-[72px] bg-white bg-opacity-50 mt-2 rounded-lg animate-pulse"></div>
        </div>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, onBeforeMount, onMounted, ref, watch } from 'vue'
import { useAssetStore } from '@/stores/assets.store'
import { IAsset } from '@/types/atomicapi/asset'
import { useCoreStore } from '@/stores/core.store'
import { IDataObject } from '@/types/app'
import GridHeader from '@/components/common/GridHeader.vue'
import { onBeforeRouteLeave, useRoute } from 'vue-router'
import EndOfPageTrigger from '@/components/common/EndOfPageTrigger.vue'
import CardAsset from '@/components/common/CardAsset.vue'
import SelectSort from '@/components/common/SelectSort.vue'
import { useHead } from '@vueuse/head'
import { selectedChain } from 'vue-3-useeosiowallet'
import { useTemplatesStore } from '@/stores/templates.store'

export default defineComponent({
    name: 'PageAssets',
    components: { SelectSort, CardAsset, EndOfPageTrigger, GridHeader },
    setup() {
        const route = useRoute()
        const routeQuery = computed(() => route.query)
        const collection_name = computed(() => route.query.collection_name ?? '')
        const template_id = computed(() => route.query.template_id ?? '')
        const template = computed(() => {
            const found = useTemplatesStore().templates.find(
                (x) => x.template_id === template_id.value,
            )
            if (found) return found
            return null
        })
        const coreStore = useCoreStore()
        const assetStore = useAssetStore()
        const isLoading = ref(false)
        const assets = ref<IAsset[]>([])
        const page = ref(1)
        const pagesMax = computed(() => assetStore.pagesMax)

        const sortOptions = [
            { id: 1, value: 'asset_id', label: 'Asset ID', disabled: false },
            { id: 2, value: 'minted', label: 'Minted', disabled: false },
            { id: 3, value: 'Transferred', label: 'Transferred', disabled: false },
            { id: 4, value: 'template_mint', label: 'Mint #', disabled: false },
        ]

        const fetchParams = (): IDataObject => {
            return {
                template_id: '',
                order: coreStore.defaultOrder,
                sort: 'asset_id',
                limit: coreStore.assetsPerPage.toString(),
                page: page.value.toString(),
                ...routeQuery.value,
            }
        }

        const loadData = async () => {
            assets.value = []
            isLoading.value = true
            page.value = 1
            const params = fetchParams()
            assets.value = await assetStore.fetchAssets(params)
            isLoading.value = false
        }

        const loadNextPage = async () => {
            if (assetStore.pagesMax || !hasMounted.value || isLoading.value) return
            page.value++
            const params = fetchParams()
            const data = await assetStore.fetchMoreAssets(params)
            assets.value = assets.value.concat(data)
        }

        onBeforeMount(async () => {
            await loadData()
            page.value = Math.ceil(assets.value.length / 12) ?? 1
        })

        const hasMounted = ref(false)
        onMounted(() => (hasMounted.value = true))

        const isLeavingRoute = ref(false)
        onBeforeRouteLeave(() => (isLeavingRoute.value = true))

        watch(routeQuery, async () => {
            if (isLeavingRoute.value) return
            await loadData()
        })

        useHead({
            title: computed(
                () =>
                    `dotGems | ${selectedChain.value.id.toUpperCase()} | Assets: ${
                        collection_name.value
                    }`,
            ),
        })

        return {
            collection_name,
            assets,
            isLoading,
            loadNextPage,
            sortOptions,
            hasMounted,
            pagesMax,
            template,
        }
    },
})
</script>
