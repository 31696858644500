<template>
    <div v-if="asset" class="lg:mt-10 mb-20">
        <AssetHeader :asset="asset" class="lg:hidden" />
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-10 xl:mx-10">
            <slot name="media"><MediaView :asset="asset" /></slot>
            <div>
                <AssetHeader :asset="asset" class="hidden lg:block" />

                <BaseTabs v-model="currentTab" :tabs="tabs">
                    <section v-if="currentTab === 1">
                        <AssetDetails :asset="asset" />
                    </section>
                    <section v-if="currentTab === 2">
                        <ImmutableData :data="asset.data" />
                    </section>
                </BaseTabs>

                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from 'vue'
import { IAsset } from '@/types/atomicapi/asset'
import MediaView from '@/components/common/MediaView.vue'
import AssetDetails from '@/components/asset/AssetTabDetails.vue'
import BaseTabs from '@/components/base/BaseTabs.vue'
import ImmutableData from '@/components/asset/AssetTabImmutableData.vue'
import AssetHeader from '@/components/asset/AssetHeader.vue'

export default defineComponent({
    name: 'AssetMain',
    components: { AssetHeader, ImmutableData, BaseTabs, AssetDetails, MediaView },
    props: {
        asset: { type: Object as PropType<IAsset>, required: true },
    },
    setup() {
        const currentTab = ref(1)

        const tabs = [
            { id: 1, name: 'Details' },
            { id: 2, name: 'Immutable Data' },
        ]
        return { currentTab, tabs }
    },
})
</script>
